import React, { Component } from 'react';

import './ErrorState.css';

export default class ErrorState extends Component {

  render() {
    return (
      <div className="error-state">
        <div className="error-state-box">
          <h1>Error()</h1>
          <h2>Something just went very wrong.</h2>
          <p>If you keep seeing this error please <a href="mailto:info@devnq.info">contact us</a>.</p>
        </div>
      </div>
    );
  }
}
