import React, { Component } from 'react';
import {connect} from 'react-redux';
import queryString from 'querystring';

import AuthLoader from './../components/AuthLoader/AuthLoader';
import {authReset} from './../actions/auth';

import auth from '../auth';

class AuthCallbackApp extends Component {

  redirectRoute;

  constructor(props) {
    super(props);
    this.redirectRoute = this.getRedirectFromURL(this.props.location);
  }

  componentDidMount() {
    const {dispatch, location} = this.props;

    dispatch(authReset());
    if (/access_token|id_token|error/.test(location.hash)) {
      auth.handleAuthentication();
    }
  }

  getRedirectFromURL(location) {
    const cleanSearch = (location.search || "").replace(/^\?/, '');
    const query = queryString.parse(cleanSearch);
    return query[auth.REDIRECT_PARAM] || null;
  }

  render() {
    const {isError} = this.props;
    const isAuthenticated = auth.isAuthenticated();

    return (<AuthLoader isAuthenticated={isAuthenticated} isError={isError} redirectRoute={this.redirectRoute}/>);
  }
}

function mapStateToProps(state) {
  const {auth} = state;
  const {hasAttempted, isLoading, isError} = auth;

  return {
    hasAttempted,
    isLoading,
    isError
  };
}

export default connect(mapStateToProps)(AuthCallbackApp);