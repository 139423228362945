import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

class AuthLoader extends Component {
  render() {
    const {isAuthenticated, isError, redirectRoute} = this.props;

    return (
      <div>
        {isAuthenticated && (
          <Redirect to={redirectRoute} />
        )}
        {isError && (
          <h2>An error occurred during authentication</h2>
        )}
        {!isAuthenticated && !isError && (
          <div>
            Loading...
          </div>
        )}
      </div>
    );
  }
};

export default AuthLoader;