import React, { Component } from 'react';
import {connect} from 'react-redux';

import ManagePageContent from './../components/ManagePageContent/ManagePageContent';
import AuthenticatedContent from './../components/AuthenticatedContent/AuthenticatedContent';
import {loadUserCompanies} from '../actions/userCompanies';

class ManageRouteWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.onAuthenticate = this.onAuthenticate.bind(this);
  }

  onAuthenticate() {
    const {dispatch, userCompaniesState, onAuthenticate} = this.props;

    if(!userCompaniesState.isLoading && !userCompaniesState.hasLoaded) {
      dispatch(loadUserCompanies());
    }

    if(onAuthenticate) {
      onAuthenticate();
    }
  }

  render() {
    const {companiesState, userCompaniesState, children, currentCompanyId} = this.props;

    return (
      <AuthenticatedContent onAuthenticate={this.onAuthenticate}>
        <ManagePageContent
          companiesState={companiesState}
          userCompaniesState={userCompaniesState}
          currentCompanyId={currentCompanyId}
        >
          {children}
        </ManagePageContent>
      </AuthenticatedContent>
    )
  }
}

function mapStateToProps(state) {
  const {userCompanies, companies} = state;

  return {
    userCompaniesState: userCompanies,
    companiesState: companies
  };
}

export default connect(mapStateToProps)(ManageRouteWrapper)