import React, { Component } from 'react';

import {apiFetch} from '../../api';
import CustomTypeahead from './../CustomTypeahead/CustomTypeahead';

export default class JobsFilterTechnology extends Component {

  state;

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false
    };

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(query) {
    if(!this.state.options) {
      this.setState({isLoading: true});
      return apiFetch(`/locations`)
        .then(resp => resp.json())
        .then(json => {
          this.setState({
            isLoading: false,
            options: json.results,
          });
        });
    };

    return Promise.resolve();
  }

  render() {
    const {onChange, value} = this.props;

    return (
      <CustomTypeahead
        id={this.props.id}
        multiple={true}
        placeholder="eg. 'Cairns, Queensland'"
        selected={value}
        isLoading={this.state.isLoading}
        options={this.state.options}
        onSearch={this.onSearch}
        onChange={onChange}
      />
    );
  }
}