import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

import './JobsListing.css';

class JobsListingSkeleton extends Component {
  render() {
    return (
      <div className="job-listing" id="job_{job.job_id}">
        <h3 className="job-title">
          <Skeleton width={Math.floor((Math.random() * 500) + 200)} />
        </h3>
        <div className="job-details">
          <Skeleton width={Math.floor((Math.random() * 500) + 100)}/>
        </div>
        <div className="job-description">
          <Skeleton count={3} />
        </div>
        <div className="job-technologies">
          <Skeleton width={Math.floor((Math.random() * 200) + 50)}/>
        </div>
      </div>
    );
  }
}

export default JobsListingSkeleton;