import React, { Component } from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import './CustomTypeahead.css';

export default class CustomTypeahead extends Component {

  render() {
    return (
      <AsyncTypeahead
        {...this.props}
        selectHintOnEnter={true}
        className={`typeahead ${this.props.className || ''}`}
      />
    )
  }
}
