import { combineReducers } from 'redux';

import jobsListReducer from './jobsList';
import authReducer from './auth';
import companiesReducer from './companies';
import userCompaniesReducer from './userCompanies';
import jobsReducer from './jobs';

export default combineReducers({
  auth: authReducer,
  jobsList: jobsListReducer,
  companies: companiesReducer,
  userCompanies: userCompaniesReducer,
  jobs: jobsReducer
});


