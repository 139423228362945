import React, { Component } from 'react';
import * as moment from 'moment';

import {Container, Button, ButtonGroup, Collapse, Card, Modal, Form, Row, Col} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import FormFooter from "./../FormFooter/FormFooter";
import TextEditor from "./../TextEditor/TextEditor";
import TimePicker from './../TimePicker/TimePicker';
import DatePicker from './../DatePicker/DatePicker';
import ManageJobTechnologyField from './../ManageJobTechnologyField/ManageJobTechnologyField';
import ManageJobLocationField from './../ManageJobLocationField/ManageJobLocationField';
import Checkbox from "../Checkbox/Checkbox";
import JobFull from "../JobFull/JobFull";

import 'react-day-picker/lib/style.css';
import './ManageJob.css';

import JobSalaryRange from "../JobSalaryRange/JobSalaryRange";

import {JOB_TYPE_FULL_TIME, JOB_TYPE_PART_TIME, JOB_TYPE_CASUAL,
  JOB_TYPE_CONTRACT, JOB_TYPE_OTHER, SALARY_PERIOD_TOTAL, SALARY_PERIOD_YEARLY,
  SALARY_PERIOD_MONTHLY, SALARY_PERIOD_HOURLY} from './../../actions/jobs';

const APPLICATION_TYPE_ONLINE = 'APPLICATION_TYPE_ONLINE';
const APPLICATION_TYPE_EMAIL = 'APPLICATION_TYPE_EMAIL';
const APPLICATION_TYPE_BOTH = 'APPLICATION_TYPE_BOTH';

const APPLICATION_CLOSE_DATE = 'APPLICATION_CLOSE_DATE';
const APPLICATION_CLOSE_MANUAL = 'APPLICATION_CLOSE_MANUAL';

export default class ManageJob extends Component {

  state = {};

  constructor(props) {
    super(props);

    this.state = {
      isSaved: false,
      isInvalid: false,
      isValidated: false,
      applicationType: APPLICATION_TYPE_ONLINE,
      applicationClose: APPLICATION_CLOSE_MANUAL,
      datePickerOpen: false
    };

    this.onSave = this.onSave.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onShowPreview = this.onShowPreview.bind(this);
    this.onHidePreview = this.onHidePreview.bind(this);
    this.onTechnologyChange = this.onTechnologyChange.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.onRemoteChange = this.onRemoteChange.bind(this);
    this.onCloseDateChange = this.onCloseDateChange.bind(this);
    this.onCloseDateTimeChange = this.onCloseDateTimeChange.bind(this);
    this.onApplicationCloseChange = this.onApplicationCloseChange.bind(this);
    this.onApplicationTypeChange = this.onApplicationTypeChange.bind(this);
  }

  componentDidMount() {
    const {isLoading, job} = this.props;

    // If the company is already loaded lets set the initial state now.
    if (!this.state.job && !isLoading && job) {
      this.onJobLoaded(job);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {isLoading, job} = this.props;

    const jobStoreString = JSON.stringify(job);
    const jobStateString = JSON.stringify(this.state.job);

    // Check if there are changes to save
    if(job && jobStoreString !== jobStateString && !this.state.isSaveable) {
      this.setState(Object.assign({}, this.state, {isSaveable: true}));
    } else if(job && jobStoreString === jobStateString && this.state.isSaveable) {
      this.setState(Object.assign({}, this.state, {isSaveable: false}));
    }

    // Check if changes were just saved, if there are unsaved changes then mark
    if(job && (jobStoreString !== JSON.stringify(prevProps.job) && jobStoreString !== jobStateString)) {
      this.setState(Object.assign({}, this.state, {isSaved: true}));
    } else if(jobStateString !== JSON.stringify(prevState.job)) {
      this.setState(Object.assign({}, this.state, {isSaved: false}));
    }

    // When the external job state changes load it into the local state for this form
    if (!isLoading && job && (JSON.stringify(job) !== JSON.stringify(prevProps.job))) {
      this.onJobLoaded(job);
    }
  }

  onJobLoaded(job) {
    this.setState(Object.assign({}, this.state, {job: job}));

    // Set the appropriate states for the local form state based on loaded data.
    if(job.application_uri && job.contact_email) {
      this.setState(Object.assign({}, this.state, {applicationType: APPLICATION_TYPE_BOTH}));
    } else if (job.contact_email){
      this.setState(Object.assign({}, this.state, {applicationType: APPLICATION_TYPE_EMAIL}));
    }

    // Set the appropriate states for the local form state based on loaded data.
    if(job.close_date) {
      this.setState(Object.assign({}, this.state, {applicationClose: APPLICATION_CLOSE_DATE}));
    }
  }

  onSave(event) {
    event.stopPropagation();
    event.preventDefault();

    const {onSave} = this.props;
    const form = event.currentTarget;
    const formValidity = form.checkValidity();

    this.setState(Object.assign({}, this.state, {isSaveError: false, isInvalid: !formValidity, isValidated: true}));

    if(onSave && formValidity) {
      onSave(this.state.job);
    }
  }

  onFieldChange(event) {
    const newJob = Object.assign({}, this.state.job);

    if(event.target.type === "number") {
      const numberValue = Number(event.target.value)
      if(isNaN(numberValue)) {
        newJob[event.target.name] = Number(event.target.value) ;
      } else {
        newJob[event.target.name] =  null;
      }
    } else {
      newJob[event.target.name] = event.target.value;
    }

    this.setState(Object.assign({}, this.state, {job: newJob}));
  }

  onShowPreview() {
    this.setState(Object.assign({}, this.state, {showPreview: true}));
  }

  onHidePreview() {
    this.setState(Object.assign({}, this.state, {showPreview: false}));
  }

  onLocationChange(location) {
    if(location[0]) {
      const newJob = Object.assign({}, this.state.job);
      newJob.location = location[0];
      this.setState(Object.assign({}, this.state, {job: newJob}));
    }
  }

  onTechnologyChange(tech_tags) {
    const newJob = Object.assign({}, this.state.job);
    newJob.tech_tags = tech_tags;
    this.setState(Object.assign({}, this.state, {job: newJob}));
  }

  onRemoteChange(remote) {
    const newJob = Object.assign({}, this.state.job);
    newJob.remote = remote;
    this.setState(Object.assign({}, this.state, {job: newJob}));
  }

  onApplicationTypeChange(event) {
    const newState = Object.assign({}, this.state);
    newState.applicationType = event.target.name;

    if(event.target.name !== APPLICATION_TYPE_ONLINE && event.target.name !== APPLICATION_TYPE_BOTH) {
      newState.job.application_uri = "";
    } else if(event.target.name !== APPLICATION_TYPE_EMAIL && event.target.name !== APPLICATION_TYPE_BOTH) {
      newState.job.contact_email = "";
    }

    this.setState(newState);
  }

  onApplicationCloseChange(event) {
    const newState = Object.assign({}, this.state);
    newState.applicationClose = event.target.name;

    if(event.target.name !== APPLICATION_CLOSE_DATE) {
      newState.job.close_date = "";
    }

    this.setState(newState);
  }

  onCloseDateChange(date) {
    const newState = Object.assign({}, this.state);
    let newDate = moment(newState.job.close_date);

    // If the derived date is not valid set to the current time.
    if(!newDate.isValid()) {
      newDate = moment();
    }

    if(date && date.isValid()) {
      newDate.year(date.year());
      newDate.month(date.month());
      newDate.date(date.date());

      newState.job.close_date = newDate.toISOString();
    } else {
      newState.job.close_date = "";
    }

    this.setState(newState);
  }

  onCloseDateTimeChange(time) {
    const newState = Object.assign({}, this.state);
    const newDate = moment(newState.job.close_date || undefined);

    if(newDate.isValid() && time && time.isValid()) {
      newDate.hour(time.hour());
      newDate.minute(time.minute());
      newDate.second(0);

      newState.job.close_date = newDate.toISOString();
    } else {
      newState.job.close_date = "";
    }

    this.setState(newState);
  }

  render() {
    const {isLoading, isSaving, isSaveError, onSaveErrorDismiss, currentCompanyState} = this.props;
    const {isInvalid, isValidated, isSaved, job} = this.state;

    return (
      <div className="manage-job">
        <h2>Editing Job Listing</h2>
        <Form
          noValidate
          validated={isValidated}
          onSubmit={this.onSave}
        >
          <Card>
            <Container>
              <Form.Group controlId="job-title-input">
                <Form.Label>
                  Job Title
                </Form.Label>
                {(!job || isLoading) && (
                  <Skeleton height={46} className="form-control"/>
                )}
                {job && !isLoading && (
                  <Form.Control
                    type="text"
                    className="form-control-lg"
                    name="title"
                    value={job.title}
                    required={true}
                    placeholder="eg. 'Software Engineer', 'Integrations Developer'"
                    title="Title should the title of the job position. This field is required."
                    onChange={this.onFieldChange}
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  Title is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Row>
                <Col>
                  <Form.Group controlId="job-type-input">
                    <Form.Label>
                      Position Type
                    </Form.Label>
                    {(!job || isLoading) && (
                      <Skeleton height={36} className="form-control"/>
                    )}
                    {job && !isLoading && (
                      <Form.Control
                        as="select"
                        value={job.type}
                        name="type"
                        onChange={this.onFieldChange}
                      >
                        <option value={JOB_TYPE_FULL_TIME}>Full Time</option>
                        <option value={JOB_TYPE_PART_TIME}>Part Time</option>
                        <option value={JOB_TYPE_CASUAL}>Casual</option>
                        <option value={JOB_TYPE_CONTRACT}>Contract</option>
                        <option value={JOB_TYPE_OTHER}>Other</option>
                      </Form.Control>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Location should be a http or https website url. eg. https://google.com
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>

                </Col>
              </Form.Row>
            </Container>
          </Card>
          <h3 className="card-title">Job Description</h3>
          <Card className="card-filled">
            {(!job || isLoading) && (
              <Skeleton height={300} className="form-control"/>
            )}
            {job && !isLoading && (
              <TextEditor
                value={job.description}
                id="company-description-input"
                name="description"
                maxLength={10000}
                placeholder="Enter a description of your role and any required qualifications to help jobseekers understand your role."
                onChange={this.onFieldChange}
              />
            )}
          </Card>
          <h3>Location</h3>
          <Card className="card-filled">
            <Container>
              <Row>
                <Col lg={8} className="card-padding">
                  <Form.Group controlId="job-location-input">
                    <Form.Label>
                      Primary work location
                    </Form.Label>
                    {(!job || isLoading) && (
                      <Skeleton height={36} className="form-control"/>
                    )}
                    {job && !isLoading && (
                      <ManageJobLocationField
                        id="job-location-input"
                        value={job.location}
                        onChange={this.onLocationChange}
                      />
                    )}
                  </Form.Group>
                  <div className="float-right">
                    <img src="/images/powered_by_google.png" alt="Powered by Google" />
                  </div>
                  <Form.Group controlId="job-remote-input">
                    {(!job || isLoading) && (
                      <Skeleton height={36} className="form-control"/>
                    )}
                    {job && !isLoading && (
                      <Checkbox
                        id="job-remote-input"
                        label="Remote working available"
                        name="remote"
                        checked={job.remote}
                        onChange={this.onRemoteChange}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col lg={4} className="card-padding filled-block filled-block-right">
                  <h4 className="tip-title">Remote and On-premise workers?</h4>
                  <p className="tip-body">
                    You can select both a <em>Primary work location</em> and check <em>Remote working available</em>.
                  </p>
                </Col>
              </Row>
            </Container>
          </Card>
          <h3>Technologies</h3>
          <Card>
            <Container>
              <Form.Group controlId="job-technologies-input">
                <Form.Label>
                  Technologies
                </Form.Label>
                {(!job || isLoading) && (
                  <Skeleton height={36} className="form-control"/>
                )}
                {job && !isLoading && (
                  <ManageJobTechnologyField
                    id="job-technologies-input"
                    value={job.tech_tags}
                    onChange={this.onTechnologyChange}
                  />
                )}
              </Form.Group>
            </Container>
          </Card>
          <h3>Remuneration</h3>
          <Card className="card-filled">
            <Container>
              <Row>
                <Col lg={8} className="card-padding">
                  <Form.Row>
                    <Form.Group as={Col} controlId="job-salary-range-lower-input">
                      <Form.Label>
                        Minimum
                      </Form.Label>
                      {(!job || isLoading) && (
                        <Skeleton height={36} className="form-control"/>
                      )}
                      {job && !isLoading && (
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <Form.Control
                            type="number"
                            value={job.salary_range_lower}
                            name="salary_range_lower"
                            onChange={this.onFieldChange}
                            aria-label="Amount (to the nearest dollar)"
                          />
                        </div>
                      )}
                      <Form.Control.Feedback type="invalid">
                        Location should be a http or https website url. eg. https://google.com
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="job-salary-range-lower-input">
                      <Form.Label>
                        Maximum
                      </Form.Label>
                      {(!job || isLoading) && (
                        <Skeleton height={36} className="form-control"/>
                      )}
                      {job && !isLoading && (
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <Form.Control
                            type="number"
                            value={job.salary_range_upper}
                            name="salary_range_upper"
                            onChange={this.onFieldChange}
                            aria-label="Amount (to the nearest dollar)"
                          />
                        </div>
                      )}
                      <Form.Control.Feedback type="invalid">
                        Location should be a http or https website url. eg. https://google.com
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Group controlId="job-salary-period-input">
                    {(!job || isLoading) && (
                      <Skeleton height={36} className="form-control"/>
                    )}
                    {job && !isLoading && (
                      <Form.Control
                        as="select"
                        value={job.salary_period}
                        name="salary_period"
                        onChange={this.onFieldChange}
                      >
                        <option value={SALARY_PERIOD_YEARLY}>per annum</option>
                        <option value={SALARY_PERIOD_MONTHLY}>per month</option>
                        <option value={SALARY_PERIOD_HOURLY}>per hour</option>
                        <option value={SALARY_PERIOD_TOTAL}>total</option>
                      </Form.Control>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={4} className="card-padding filled-block filled-block-right">
                    <h4 className="tip-title">Preview</h4>
                    {job && !isLoading && (
                      <JobSalaryRange className="preview-content" rangeLower={job.salary_range_lower} rangeUpper={job.salary_range_upper} rangePeriod={job.salary_period}/>
                    )}
                </Col>
              </Row>
            </Container>
          </Card>
          <h3>Job Application</h3>
          <Card className="card-filled">
            <Container>
              <Form.Group as={Row} className="filled-block card-padding" controlId="job-application-type-input">
                <Form.Label column lg={6}>
                  How can candidates apply?
                </Form.Label>
                <Col lg={6}>
                {(!job || isLoading) && (
                  <Skeleton height={36} className="form-control"/>
                )}
                {job && !isLoading && (
                  <ButtonGroup className="d-flex" aria-label="Type of application">
                    <Button onClick={this.onApplicationTypeChange} name={APPLICATION_TYPE_ONLINE} active={this.state.applicationType === APPLICATION_TYPE_ONLINE}>
                      Online
                    </Button>
                    <Button onClick={this.onApplicationTypeChange} name={APPLICATION_TYPE_EMAIL} active={this.state.applicationType === APPLICATION_TYPE_EMAIL}>
                      Email
                    </Button>
                    <Button onClick={this.onApplicationTypeChange} name={APPLICATION_TYPE_BOTH} active={this.state.applicationType === APPLICATION_TYPE_BOTH}>
                      Both
                    </Button>
                  </ButtonGroup>
                )}
                </Col>
              </Form.Group>
              <Collapse in={this.state.applicationType === APPLICATION_TYPE_ONLINE || this.state.applicationType === APPLICATION_TYPE_BOTH}>
                <Form.Group controlId="job-location-input">
                  <Form.Label>
                    External Application URL
                  </Form.Label>
                  {(!job || isLoading) && (
                    <Skeleton height={36} className="form-control"/>
                  )}
                  {job && !isLoading && (
                    <Form.Control
                      type="url"
                      value={job.application_uri}
                      name="application_uri"
                      placeholder="http://careers.yourbrand.com/job/j29osb"
                      pattern="^(https?)://[^\s/$.?#].[^\s]*$"
                      required={(this.state.applicationType === APPLICATION_TYPE_ONLINE || this.state.applicationType === APPLICATION_TYPE_BOTH)}
                      onChange={this.onFieldChange}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    External Application URL should be a http or https website url. eg. http://careers.yourbrand.com/job/j29osb
                  </Form.Control.Feedback>
                </Form.Group>
              </Collapse>
              <Collapse in={this.state.applicationType === APPLICATION_TYPE_EMAIL || this.state.applicationType === APPLICATION_TYPE_BOTH}>
                <Form.Group controlId="job-location-input">
                  <Form.Label>
                    Applicant Contact Email
                  </Form.Label>
                  {(!job || isLoading) && (
                    <Skeleton height={36} className="form-control"/>
                  )}
                  {job && !isLoading && (
                    <Form.Control
                      type="email"
                      value={job.contact_email}
                      name="contact_email"
                      placeholder="careers@yourbrand.com"
                      pattern="^.*@.*$"
                      required={(this.state.applicationType === APPLICATION_TYPE_EMAIL || this.state.applicationType === APPLICATION_TYPE_BOTH)}
                      onChange={this.onFieldChange}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    Applicant contact email should be a valid email. eg. careers@yourbrand.com
                  </Form.Control.Feedback>
                </Form.Group>
              </Collapse>
              <Form.Group as={Row} className="filled-block card-padding" controlId="job-application-type-input">
                <Form.Label column lg={6}>
                  When would you like this listing to end?
                </Form.Label>
                <Col lg={6}>
                {(!job || isLoading) && (
                  <Skeleton height={36} className="form-control"/>
                )}
                {job && !isLoading && (
                  <ButtonGroup className="d-flex" aria-label="Application closing process">
                    <Button onClick={this.onApplicationCloseChange} name={APPLICATION_CLOSE_DATE} active={this.state.applicationClose === APPLICATION_CLOSE_DATE}>
                      On a certain date
                    </Button>
                    <Button onClick={this.onApplicationCloseChange} name={APPLICATION_CLOSE_MANUAL} active={this.state.applicationClose === APPLICATION_CLOSE_MANUAL}>
                      Manually
                    </Button>
                  </ButtonGroup>
                )}
                </Col>
              </Form.Group>
              <Collapse in={this.state.applicationClose === APPLICATION_CLOSE_MANUAL}>
                <p>You can choose to close this job at any time through the DevNQ Jobs management console.</p>
              </Collapse>
              <Collapse in={this.state.applicationClose === APPLICATION_CLOSE_DATE}>
                <div>
                  <Form.Group controlId="job-salary-range-lower-input">
                    <Form.Label>
                      Listing end date and time
                    </Form.Label>
                    {(!job || isLoading) && (
                      <Skeleton height={36} className="form-control"/>
                    )}
                    {job && !isLoading && (
                      <Form.Row>
                        <Col lg={4} xs={6}>
                          <DatePicker
                            value={job.close_date}
                            required={this.state.applicationClose === APPLICATION_CLOSE_DATE}
                            onChange={this.onCloseDateChange}
                            aria-label="Listing Close Date"
                          />
                        </Col>
                        <Col lg={4} xs={6}>
                          <TimePicker
                            value={job.close_date}
                            defaultOpenValue={moment().hour(17).minute(0)}
                            onChange={this.onCloseDateTimeChange}
                            aria-label="Listing Close Time"
                          />
                        </Col>
                      </Form.Row>
                    )}
                  </Form.Group>
                </div>
              </Collapse>
            </Container>
          </Card>
          <FormFooter
            isLoading={isLoading}
            isSaving={isSaving}
            isSaveError={isSaveError}
            isSaved={isSaved}
            isInvalid={isInvalid}
            onShowPreview={this.onShowPreview}
            onSaveErrorDismiss={onSaveErrorDismiss}
          />
        </Form>
        <Modal show={this.state.showPreview} onHide={this.onHidePreview} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Job Listing Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JobFull
              jobState={{isLoading: false, job: this.state.job}}
              companyState={currentCompanyState}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}