
// Full hostname and port (if not default)
let HOST = 'jobs.devnq.org';
// Preferred protocol
let PROTO = 'https';

let API_BASE_PATH = `//${HOST}/api`;

if(process.env['NODE_ENV'] === "development") {
  HOST = 'localhost:3000';
  PROTO = 'http';
  API_BASE_PATH = '//localhost:3001';
}

const AUTH_DOMAIN = 'devnq.auth0.com';
const AUTH_CALLBACK = `${PROTO}://${HOST}/auth/callback`;
const AUTH_LOGOUT = `${PROTO}://${HOST}/`;
const AUTH_CLIENT_ID = '75dL9GYwnEZKHS0W0JSVBUuRCVdKatmC';
const AUTH_AUDIENCE = 'https://jobs.devnq.org';

export {
  API_BASE_PATH,
  AUTH_DOMAIN,
  AUTH_CALLBACK,
  AUTH_LOGOUT,
  AUTH_CLIENT_ID,
  AUTH_AUDIENCE
}