import React, { Component } from 'react';
import fetch from 'cross-fetch';
import uuid from 'uuid/v4';

import CustomTypeahead from './../CustomTypeahead/CustomTypeahead';

const API_KEY = 'AIzaSyDaf48FieYgGUX0SFA5FdxKBX2PXKln1kc';

export default class JobsFilterTechnology extends Component {

  state;
  session;

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      hasLoaded: false,
      options: []
    };
    this.session = uuid();

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(query) {
    this.setState({isLoading: true});
    return fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${API_KEY}&input=${encodeURI(query)}&sessiontoken=${this.session}&types=(cities)&components=country:au`)
      .then(resp => resp.json())
      .then(json => {
        const results = json.predictions.map((location) => {
          return `${location.terms[0].value}, ${location.terms[1].value}`
        });

        this.setState({
          isLoading: false,
          hasLoaded: true,
          options: results,
        });
      });
  }

  render() {
    const {onChange, value} = this.props;

    return (
      <CustomTypeahead
        id={this.props.id}
        defaultSelected={[value]}
        placeholder="eg. 'Townsville, QLD', 'Mareeba, QLD'"
        isLoading={this.state.isLoading}
        options={this.state.options}
        onSearch={this.onSearch}
        onChange={onChange}
      />
    );
  }
}