import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import ManageNav from './../ManageNav/ManageNav';

import './ManagePageContent.css';

export default class ManagePageContent extends Component {
  render() {
    const {companiesState, userCompaniesState, currentCompanyId, currentPath, currentRoute} = this.props;

    return (
      <div className="manage-page-content">
        <Container>
          <Row>
            <Col md={4} lg={3}>
              <ManageNav
                companiesState={companiesState}
                userCompaniesState={userCompaniesState}
                currentCompanyId={currentCompanyId}
                currentPath={currentPath}
                currentRoute={currentRoute}
              />
            </Col>
            <Col md={8} lg={9}>
              {this.props.children}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}