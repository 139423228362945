export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_NOCREDS = 'AUTH_NOCREDS';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_START = 'AUTH_START';

/**
 * An authentication process has started. This will mutate the state to tell components that the authentication system
 * is currently trying to authenticate.
 * @return {{type: string}}
 */
export function authStart() {
  return {
    type: AUTH_START
  };
}

/**
 * A user was successfully authentication from a session or token authentication.
 * @return {{type: string}}
 */
export function authSuccess() {
  return {
    type: AUTH_SUCCESS
  };
}

/**
 * No credentials were found, however this does not indicate an error.
 * @return {{type: string}}
 */
export function authNoCredentials() {
  return {
    type: AUTH_NOCREDS
  };
}

/**
 * There was an error in authenticating the user session or tokens.
 * @return {{type: string}}
 */
export function authFailure() {
  return {
    type: AUTH_FAILURE
  };
}

/**
 * A user has manually or been automatically de-authenticated and everything should be reset.
 * @return {{type: string}}
 */
export function authReset() {
  return {
    type: AUTH_RESET
  };
}

