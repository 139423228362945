import React, { Component } from 'react';
import * as moment from 'moment';

import {Form, InputGroup} from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {formatDate, parseDate} from 'react-day-picker/moment';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDay} from '@fortawesome/pro-regular-svg-icons';

import 'rc-time-picker/assets/index.css';
import './DatePicker.css';

export default class DatePicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isInvalid: false
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {value, required} = this.props;

    if(value !== prevProps.value || required !== prevProps.required) {
      this.validate(moment(value), required);
    }
  }

  onChange(rawDate) {
    console.log(rawDate)
    const date = moment(rawDate || "");
    const {required, onChange} = this.props;

    this.validate(date, required);
    onChange(date);
  }

  validate(date, required) {
    console.log('isInvalid', !date.isValid())

    if(required) {
      this.setState(Object.assign({}, this.state, {isInvalid: !date.isValid()}));
    }
  }

  render() {
    const {value, required} = this.props;
    const {isInvalid} = this.state;

    return (
      <Form.Group className="form-date-group" controlId="close-date-date-input">
        <InputGroup className="date-input-group">
          <InputGroup.Prepend>
            <InputGroup.Text onClick={this.toggleDatePicker}>
              <FontAwesomeIcon icon={faCalendarDay}/>
              <span className="sr-only">Open day picker</span>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <DayPickerInput
            {...this.props}
            value={(value ? moment.utc(value).local().format('YYYY-MM-DD') : "")}
            classNames={{
              container: `DayPickerInput form-control ${isInvalid ? 'is-invalid' : ''}`,
              overlayWrapper: 'DayPickerInput-OverlayWrapper',
              overlay: 'DayPickerInput-Overlay date-picker-overlay'
            }}
            inputProps={{
              id: 'close-date-date-input',
              required: required
            }}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder="YYYY-MM-DD"
            onDayChange={this.onChange}
            aria-label="Listing Close Date"
          />
        </InputGroup>
        {isInvalid && (
          <div className="invalid-feedback" >
            A valid date must be selected
          </div>
        )}
      </Form.Group>
    )
  }

}