import React, { Component } from 'react';
import {Container, Row, Col, Badge} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import ReactMarkdown from 'react-markdown';

import ErrorState from './../ErrorState/ErrorState';
import TechTags from './../TechTags/TechTags';
import JobTypeLabel from './../JobTypeLabel/JobTypeLabel';
import JobSalaryRange from './../JobSalaryRange/JobSalaryRange';
import JobApplicationButtons from './../JobApplicationButtons/JobApplicationButtons';

import './JobFull.css'

export default class JobFull extends Component {
  render() {
    const {isLoading = true, isError, job = {}} = this.props.jobState;
    const {isLoading: isCompanyLoading = true, company = {}} = this.props.companyState;

    return (
      <Container className="job">
        {isError && (
          <ErrorState />
        )}
        {!isError && (
            <div>
            <div className="job-header">
              <h1 className="job-title">{job.title || <Skeleton width={400}/>}</h1>
              <div className="job-company">
                {isCompanyLoading && <Skeleton width={200}/>}
                {!isCompanyLoading && (
                  <a href={company.website}>
                    {company.name}
                  </a>
                )}
              </div>
            </div>
            <Row>
              <Col md={8} lg={9}>
                {!isLoading && (
                  <div>
                    <ReactMarkdown className="markdown-content">
                      {job.description}
                    </ReactMarkdown>
                    <JobApplicationButtons
                      applicationUri={job.application_uri}
                      contactEmail={job.contact_email}
                    />
                  </div>
                )}
                {isLoading && (
                  <div>
                    <p><Skeleton count={4} /></p>
                    <p><Skeleton count={5} /></p>
                    <p><Skeleton count={4} /></p>
                    <Skeleton height={36} width={100} />
                  </div>
                )}
                <hr />
                <h3>{(company.name && (<span>About {company.name}</span>)) || (<Skeleton width={400}/>)}</h3>
                {!isCompanyLoading && (
                  <ReactMarkdown className="markdown-content">
                    {company.description}
                  </ReactMarkdown>
                )}
                {isLoading && (<Skeleton count={4} />)}
              </Col>
              <Col md={4} lg={3}>
                <div className="d-block d-md-none">
                  <hr />
                  <h3>About this role</h3>
                </div>
                <div className="job-sidebar-button-area d-none d-md-block">
                  {isLoading && (<Skeleton height={36} count="2"/>)}
                  <JobApplicationButtons
                    applicationUri={job.application_uri}
                    contactEmail={job.contact_email}
                    block={true}
                  />
                </div>
                <dl>
                  <dt>
                    Employment Type
                  </dt>
                  <dd>
                    {isLoading && <Skeleton />}
                    {!isLoading && (
                      <JobTypeLabel jobType={job.type} />
                    )}
                  </dd>
                  <dt>
                    Remuneration
                  </dt>
                  <dd>
                    {isLoading && (
                      <Skeleton />
                    )}
                    {!isLoading && (
                      <JobSalaryRange rangeLower={job.salary_range_lower} rangeUpper={job.salary_range_upper} rangePeriod={job.salary_period} />
                    )}
                  </dd>
                  <dt>
                    Location
                  </dt>
                  <dd>
                    <div className="job-location">
                      {isLoading && (
                        <Skeleton />
                      )}
                      {!isLoading && job.location && (
                        <span>
                          {job.location}
                        </span>
                      )}
                    </div>
                    <div className="job-remote">
                      {isLoading && (
                        <Skeleton />
                      )}
                      {!isLoading && job.remote && (
                        <Badge variant="primary">Remote Work Available</Badge>
                      )}
                    </div>
                  </dd>
                  <dt>
                    Technologies
                  </dt>
                  <dd>
                    {isLoading && (
                      <Skeleton count={2} />
                    )}
                    {!isLoading && (
                      <TechTags tags={job.tech_tags} />
                    )}
                  </dd>
                </dl>
                <hr />
                {!isCompanyLoading && company.claimable && (
                  <p className="small">This listing has been reproduced from a job listing on another service. DevNQ does not
                    guarantee the accuracy of any information in this job listing. If you represent {company.name} and
                    would like to claim this listing or have it removed
                    please <a href="mailto:info@devnq.org">contact us</a>.
                  </p>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Container>
    );
  }
}
