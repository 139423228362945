import React, { Component } from 'react';
import {connect} from 'react-redux';

import ManageRouteWrapper from './ManageRouteWrapper';
import ManageCompany from './../components/ManageCompany/ManageCompany';
import ErrorState from './../components/ErrorState/ErrorState';
import {saveCompany, saveCompanyFailureDismiss} from './../actions/companies';

class ManageCompanyRoute extends Component {

  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    this.onSaveErrorDismiss = this.onSaveErrorDismiss.bind(this);
  }

  onSave(company) {
    const {dispatch} = this.props;
    dispatch(saveCompany(company));
  }

  onSaveErrorDismiss() {
    const {dispatch} = this.props;
    const currentCompanyId = this.props.match.params.company_id;
    dispatch(saveCompanyFailureDismiss(currentCompanyId));
  }

  render() {
    const {companiesState, userCompaniesState} = this.props;
    const {isLoading} = userCompaniesState;
    const currentCompanyId = this.props.match.params.company_id;
    let currentCompany, isSaving, isSaveError;
    let isError = false;

    if (userCompaniesState.hasLoaded) {
      const currentCompanyState = companiesState[currentCompanyId];
      if(currentCompanyState) {
        currentCompany = currentCompanyState.company;
        isSaving = currentCompanyState.isSaving
        isSaveError = currentCompanyState.isSaveError;
      } else {
        isError = true;
      }
    }

    console.log("Current Company", currentCompanyId, currentCompany)

    return (
      <ManageRouteWrapper
        currentCompanyId={currentCompanyId}
      >
        <div>
          {!isError && (
            <ManageCompany
              currentCompanyId={currentCompanyId}
              company={currentCompany}
              isLoading={isLoading}
              isSaving={isSaving}
              isSaveError={isSaveError}
              onSave={this.onSave}
              onSaveErrorDismiss={this.onSaveErrorDismiss}
            />
          )}
          {isError && (
            <ErrorState />
          )}
        </div>
      </ManageRouteWrapper>
    )
  }
}

function mapStateToProps(state) {
  const {userCompanies, companies} = state;
  const {isLoading, isError} = userCompanies;

  return {
    isLoading,
    isError,
    userCompaniesState: userCompanies,
    companiesState: companies
  };
}

export default connect(mapStateToProps)(ManageCompanyRoute)