import React, { Component } from 'react';
import {Container, Button} from 'react-bootstrap';

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import './FormFooter.css'

export default class FormFooter extends Component {
  render() {
    const {isLoading, isSaving, isSaved, isSaveError, isInvalid, onShowPreview, onSaveErrorDismiss} = this.props;

    return (
      <div className="form-toolbar fixed-bottom">
        <Container className="d-md-flex flex-nowrap justify-content-between">
          <div className="form-alert">
            {(isSaveError && (
              <ErrorMessage onDismiss={onSaveErrorDismiss}>
                <strong>Changes could not be saved.</strong> Please validate all fields are correct and
                try again.
              </ErrorMessage>
            ))}
            {(isInvalid && (
              <ErrorMessage onDismiss={onSaveErrorDismiss}>
                <strong>Some form fields are not valid.</strong> Correct any validation errors below and try
                saving again.
              </ErrorMessage>
            ))}
            {(isSaved && (
              <strong>Changes saved...</strong>
            ))}
          </div>
          <div className="form-buttons">
            {onShowPreview && (
              <Button variant="outline-secondary" disabled={(isLoading)} onClick={onShowPreview}>
                Preview
              </Button>
            )}
            <Button type="submit" variant="primary" disabled={(isLoading || isSaving)}>
              {!isSaving && 'Save'}
              {isSaving && (
                <span>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Saving...
                </span>
              )}
            </Button>
          </div>
        </Container>
      </div>
    );
  }
}