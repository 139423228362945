import React, { Component } from 'react';
import {connect} from 'react-redux';

import auth from '../auth';

import ManageRouteWrapper from './ManageRouteWrapper';
import ManageJobsList from './../components/ManageJobsList/ManageJobsList';
import {loadJobs, changeFilters} from '../actions/jobsList';

class ManageJobsListRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.getDefaultFilters = this.getDefaultFilters.bind(this);
    this.onAuthenticate = this.onAuthenticate.bind(this);
  }

  componentDidMount() {
    const {dispatch} = this.props;

    dispatch(changeFilters(Object.assign(this.getDefaultFilters())));
  }

  componentDidUpdate(prevProps) {
    const {dispatch, filters, shouldLoad} = this.props;

    if(this.props.match.params.company_id !== prevProps.match.params.company_id)
    dispatch(changeFilters(Object.assign(this.getDefaultFilters())));

    if (auth.isAuthenticated() && shouldLoad) {
      dispatch(loadJobs(filters));
    }
  }

  onAuthenticate() {
    const {dispatch, filters, shouldLoad} = this.props;

    if (shouldLoad) {
      dispatch(loadJobs(filters));
    }
  }

  /**
   * Default filters are filters that are ALWAYS applied in this particular view. They set the minimum set of filters
   * to be applied for views like company-specific jobs listing pages.
   * @return {*}
   */
  getDefaultFilters() {
    const defaultFilters = {
      include_closed: true
    };
    const currentCompanyId = this.props.match.params.company_id;
    defaultFilters['company_id'] = currentCompanyId;

    return defaultFilters;
  }

  render() {
    const {jobs, isLoading} = this.props;
    const currentCompanyId = this.props.match.params.company_id;

    return (
      <ManageRouteWrapper currentCompanyId={currentCompanyId} onAuthenticate={this.onAuthenticate}>
        <ManageJobsList
          jobs={jobs}
          isLoading={isLoading}
          currentCompanyId={currentCompanyId}
        />
      </ManageRouteWrapper>
    )
  }
}

function mapStateToProps(state) {
  const {jobsList} = state;
  const {shouldLoad, isLoading, isError, filters, items} = jobsList;

  return {
    shouldLoad,
    isLoading,
    isError,
    jobs: items,
    filters,
  };
}

export default connect(mapStateToProps)(ManageJobsListRoute)