import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import JobsListRoute from './../JobsListRoute';
import JobRoute from './../JobRoute';
import ManageRedirectRoute from './../ManageRedirectRoute';
import ManageDashRoute from './../ManageDashRoute';
import ManageJobsListRoute from './../ManageJobsListRoute';
import ManageJobRoute from './../ManageJobRoute';
import ManageCompanyRoute from './../ManageCompanyRoute';
import AuthCallbackRoute from './../AuthCallbackRoute';

import Header from './../../components/Header/Header'
import PageContent from './../../components/PageContent/PageContent';
import Footer from './../../components/Footer/Footer';

class App extends Component {

  render() {
    return (
      <div>
        <Router>
          <div>
            <Header />
            <PageContent>
              <Route exact path="/" component={JobsListRoute}/>
              <Route exact path="/company/:company_id" component={JobsListRoute}/>
              <Route exact path="/job/:job_id" component={JobRoute}/>
              <Route exact path="/manage" component={ManageRedirectRoute}/>
              <Route exact path="/manage/:company_id" component={ManageDashRoute}/>
              <Route exact path="/manage/:company_id/jobs" component={ManageJobsListRoute}/>
              <Route exact path="/manage/:company_id/jobs/:job_id" component={ManageJobRoute}/>
              <Route exact path="/manage/:company_id/company" component={ManageCompanyRoute}/>
              <Route exact path="/auth/callback" component={AuthCallbackRoute}/>
            </PageContent>
            <Footer />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
