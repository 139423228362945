import React, { Component } from 'react';
import {connect} from 'react-redux';

import ManageRouteWrapper from './ManageRouteWrapper';

class ManageDashRoute extends Component {

  render() {
    const currentCompanyId = this.props.match.params.company_id;
    const currentPath = this.props.match.path;

    return (
      <ManageRouteWrapper
        currentCompanyId={currentCompanyId}
        currentPath={currentPath}
      >
        <div>
        </div>
      </ManageRouteWrapper>
    )
  }
}

function mapStateToProps(state) {
  const {jobs, companies} = state;

  return {
    jobs,
    companies
  };
}

export default connect(mapStateToProps)(ManageDashRoute)