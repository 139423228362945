import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_NOCREDS,
  AUTH_FAILURE,
  AUTH_RESET
} from '../actions/auth';

const DEFAULT_STATE =  {
  hasAttempted: false,
  isAuthed: false,
  isError: false,
  isLoading: false,
};

const RESET_STATE = {
  isAuthed: false,
  isError: false
};

export default function auth(state = DEFAULT_STATE, action) {
  switch(action.type) {
    case AUTH_START:
      return Object.assign({}, state, {
        hasAttempted: true,
        isLoading: true
      });
    case AUTH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      });
    case AUTH_NOCREDS:
      return Object.assign({}, state, {
        isError: false,
        isLoading: false
      });
    case AUTH_FAILURE:
      return Object.assign({}, state, {
        isError: true,
        isLoading: false
      });
    case AUTH_RESET:
      return Object.assign({}, state, RESET_STATE);
    default:
      return state;
  }
}