import React, { Component } from 'react';
import {connect} from 'react-redux';

import {setupEmptyJob, loadJob, saveJob, createJob, saveCompanyFailureDismiss, JOB_CREATE_ID} from './../actions/jobs';
import ManageRouteWrapper from './ManageRouteWrapper';
import ManageJob from './../components/ManageJob/ManageJob';
import ErrorState from './../components/ErrorState/ErrorState';

class ManageJobRoute extends Component {
  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    this.onSaveErrorDismiss = this.onSaveErrorDismiss.bind(this);
  }

  componentDidMount() {
    const {dispatch, jobs} = this.props;
    const jobId = this.props.match.params.job_id;
    const currentCompanyId = this.props.match.params.company_id;

    if(jobId !== JOB_CREATE_ID && !jobs[jobId]) {
      dispatch(loadJob(jobId));
    } else if (jobId === JOB_CREATE_ID && !jobs[jobId]) {
      dispatch(setupEmptyJob(currentCompanyId));
    }
  }

  componentDidUpdate() {
    const {jobs, history} = this.props;
    const jobId = this.props.match.params.job_id;
    const currentCompanyId = this.props.match.params.company_id;

    // If we're creating a new job and we've been assigned a job id.
    if(jobId === JOB_CREATE_ID && jobs[JOB_CREATE_ID].jobId) {
      history.push({
        pathname: `/manage/${currentCompanyId}/jobs/${jobs[JOB_CREATE_ID].jobId}`,
      });
    }
  }

  onSave(job) {
    const {dispatch} = this.props;
    const jobId = this.props.match.params.job_id;
    console.log("SAVING", this.props.match.params.job_id);

    if(jobId === JOB_CREATE_ID) {
      dispatch(createJob(job));
    } else {
      dispatch(saveJob(job));
    }
  }

  onSaveErrorDismiss() {
    const {dispatch} = this.props;
    const jobId = this.props.match.params.job_id;
    dispatch(saveCompanyFailureDismiss(jobId));
  }

  render() {
    const {jobs, companies} = this.props;
    const currentCompanyId = this.props.match.params.company_id;
    const currentCompanyState = companies[currentCompanyId];
    const jobId = this.props.match.params.job_id;
    let job, isLoading, isError, isSaving, isSaveError;

    console.log("Render Job ID", jobId);

    if (jobs[jobId]) {
      isLoading = jobs[jobId].isLoading;
      isError = jobs[jobId].isError;
      isSaving = jobs[jobId].isSaving;
      isSaveError = jobs[jobId].isSaveError;
      job = jobs[jobId].job;
    }

    return (
      <ManageRouteWrapper
        currentCompanyId={currentCompanyId}
      >
        <div>
          {!isError && (
            <ManageJob
              job={job}
              currentCompanyState={currentCompanyState}
              isLoading={isLoading}
              isSaving={isSaving}
              isSaveError={isSaveError}
              onSave={this.onSave}
              onSaveErrorDismiss={this.onSaveErrorDismiss}
            />
          )}
          {isError && (
            <ErrorState />
          )}
        </div>
      </ManageRouteWrapper>
    )
  }
}

function mapStateToProps(state) {
  const {jobs, companies} = state;

  return {
    jobs,
    companies
  };
}

export default connect(mapStateToProps)(ManageJobRoute)