import {
  USER_COMPANIES_LOAD_START,
  USER_COMPANIES_LOAD_SUCCESS,
  USER_COMPANIES_LOAD_FAILURE
} from '../actions/userCompanies';

const DEFAULT_STATE = {
  isLoading: false,
  hasLoaded: false,
  isError: true
};

export default function userCompanies(state = DEFAULT_STATE, action) {
  let newState;

  switch(action.type) {
    case USER_COMPANIES_LOAD_START:
      newState = {
        isLoading: true,
        isError: false
      };
      break;
    case USER_COMPANIES_LOAD_SUCCESS:
      const userCompanyIds = action.companies.map((company) => company.company_id);

      newState = {
        ifError: false,
        isLoading: false,
        hasLoaded: true,
        companyIds: userCompanyIds
      };
      break;
    case USER_COMPANIES_LOAD_FAILURE:
      newState = {
        isLoading: false,
        isError: true
      };
      break;
    default:
      newState = Object.assign({}, state);
      break;
  }

  return newState;
}