import {apiFetch} from '../api';
export const JOB_LOAD_START = 'JOB_LOAD_START';
export const JOB_LOAD_SUCCESS = 'JOB_LOAD_SUCCESS';
export const JOB_LOAD_FAILURE = 'JOB_LOAD_FAILURE';
export const JOB_SAVE_START = 'JOB_SAVE_START';
export const JOB_SAVE_SUCCESS = 'JOB_SAVE_SUCCESS';
export const JOB_SAVE_FAILURE = 'JOB_SAVE_FAILURE';
export const JOB_SAVE_FAILURE_DISMISS = 'JOB_SAVE_FAILURE_DISMISS';
export const JOB_CREATE_EMPTY = 'JOB_CREATE_EMPTY';
export const JOB_CREATE_START = 'JOB_CREATE_START';
export const JOB_CREATE_SUCCESS = 'JOB_CREATE_SUCCESS';
export const JOB_CREATE_FAILURE = 'JOB_CREATE_FAILURE';
export const JOB_CREATE_COMPLETE = 'JOB_CREATE_COMPLETE';

const CREATE_EXCLUDED_KEYS = [
  'add_date',
  'modified_date',
  'job_id'
];

const SAVE_EXCLUDED_KEYS = [
  'add_date',
  'modified_date',
  'company_id',
  'job_id'
];

export const JOB_TYPE_FULL_TIME = 'f';
export const JOB_TYPE_PART_TIME = 'p';
export const JOB_TYPE_CASUAL = 'c';
export const JOB_TYPE_CONTRACT = 'a';
export const JOB_TYPE_OTHER = 'o';

export const SALARY_PERIOD_HOURLY = 'H';
export const SALARY_PERIOD_MONTHLY = 'M';
export const SALARY_PERIOD_YEARLY = 'Y';
export const SALARY_PERIOD_TOTAL = 'T';

export const JOB_CREATE_ID = 'create';

export function setupEmptyJob(companyId) {
  return {
    type: JOB_CREATE_EMPTY,
    companyId
  };
}

function loadJobStart(jobId) {
  return {
    type: JOB_LOAD_START,
    jobId
  };
}

function saveJobStart(jobId) {
  return {
    type: JOB_SAVE_START,
    jobId
  };
}

function createJobStart() {
  return {
    type: JOB_CREATE_START,
  };
}

function loadJobSuccess(jobId, payload) {
  return {
    type: JOB_LOAD_SUCCESS,
    jobId,
    job: payload
  };
}

function saveJobSuccess(jobId, payload) {
  return {
    type: JOB_SAVE_SUCCESS,
    jobId,
    job: payload
  };
}

function createJobSuccess(jobId, payload) {
  return {
    type: JOB_CREATE_SUCCESS,
    jobId,
    job: payload
  };
}


function loadJobFailure(jobId) {
  return {
    type: JOB_LOAD_FAILURE,
    jobId
  }
}

function saveJobFailure(jobId) {
  return {
    type: JOB_SAVE_FAILURE,
    jobId
  }
}

function createJobFailure() {
  return {
    type: JOB_CREATE_FAILURE
  };
}

export function saveCompanyFailureDismiss(jobId) {
  return {
    type: JOB_SAVE_FAILURE_DISMISS,
    jobId
  };
}

/*
  Prepares and sanitises React data for Backend
 */
function _prepareSavePayload(job, excludeKeys) {
  return Object.keys(job).reduce((payload, jobKey) => {
    let jobValue = job[jobKey];

    // Add all non-excluded keys
    if(!excludeKeys.includes(jobKey)) {

      // Nullify all empty values.
      if(jobValue === "") {
        jobValue = null;
      }

      payload[jobKey] = jobValue;
    }

    return payload;
  }, {});
}

/*
  Prepares and sanitises Backend data for React
 */
function _prepareLoadPayload(jsonBody) {
  return Object.keys(jsonBody).reduce((newBody, jobKey) => {
    const jobValue = jsonBody[jobKey];

    if(jobValue === null) {
      newBody[jobKey] = ""
    } else {
      newBody[jobKey] = jobValue;
    }

    return newBody;
  }, {})
}

export function loadJob(JobId) {
  return dispatch => {
    dispatch(loadJobStart(JobId));
    return apiFetch(`/jobs/${encodeURI(JobId)}`)
      .then(response => {
        if(response.status !== 200) {
          dispatch(loadJobFailure(JobId));
          return;
        }

        response.json().then((jsonBody) =>
          dispatch(loadJobSuccess(JobId, _prepareLoadPayload(jsonBody))));
      })
      .catch(error => dispatch(loadJobFailure(JobId)));
  }
}

export function createJob(job) {
  return dispatch => {
    dispatch(createJobStart());

    const jobPayload = _prepareSavePayload(job, CREATE_EXCLUDED_KEYS);

    return apiFetch(`/jobs`, {
      method: "POST",
      body: JSON.stringify(jobPayload)
    })
      .then(response => {
        if (response.status !== 200) {
          dispatch(createJobFailure());
          return;
        }

        response.json().then((jsonBody) => dispatch(createJobSuccess(jsonBody.job_id, _prepareLoadPayload(jsonBody))));
      })
      .catch(error => dispatch(createJobFailure()));
  }
}

export function saveJob(job) {
  return dispatch => {
    const jobId = job.job_id;
    dispatch(saveJobStart(jobId));

    const jobPayload = _prepareSavePayload(job, SAVE_EXCLUDED_KEYS);

    return apiFetch(`/jobs/${encodeURI(jobId)}`, {
      method: "PATCH",
      body: JSON.stringify(jobPayload)
    })
      .then(response => {
        if (response.status !== 200) {
          dispatch(saveJobFailure(jobId));
          return;
        }

        response.json().then((jsonBody) => dispatch(saveJobSuccess(jobId, _prepareLoadPayload(jsonBody))));
      })
      .catch(error => dispatch(saveJobFailure(jobId)));
  }
}