import React, { Component } from 'react';

const JOB_TYPE_FULL_TIME = 'f';
const JOB_TYPE_PART_TIME = 'p';
const JOB_TYPE_CASUAL = 'c';
const JOB_TYPE_CONTRACT = 'a';
const JOB_TYPE_OTHER = 'o';

const TYPE_TEXT_ENUM = {};
TYPE_TEXT_ENUM[JOB_TYPE_FULL_TIME] = "Full Time";
TYPE_TEXT_ENUM[JOB_TYPE_PART_TIME] = "Part Time";
TYPE_TEXT_ENUM[JOB_TYPE_CASUAL] = "Casual";
TYPE_TEXT_ENUM[JOB_TYPE_CONTRACT] = "Contract";
TYPE_TEXT_ENUM[JOB_TYPE_OTHER] = "Other";

export default class JobTypeLabel extends Component {

  render() {
    const {jobType} = this.props;
    return (
      <span className="job-type-text">
        {TYPE_TEXT_ENUM[jobType]}
      </span>
    );
  }
}