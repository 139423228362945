import auth0 from 'auth0-js';

import {store} from './../state';
import {authSuccess, authFailure, authReset, authStart} from './../actions/auth';
import {AUTH_DOMAIN, AUTH_CALLBACK, AUTH_LOGOUT, AUTH_AUDIENCE, AUTH_CLIENT_ID} from './../config.js';

export default class Auth0 {
  REDIRECT_PARAM = 'redirectRoute';

  accessToken;
  idToken;
  expiresAt;
  tokenRenewalTimeout;

  auth0 = new auth0.WebAuth({
    domain: AUTH_DOMAIN,
    clientID: AUTH_CLIENT_ID,
    redirectUri: AUTH_CALLBACK,
    responseType: 'token id_token',
    audience: AUTH_AUDIENCE,
    scope: 'openid profile create:company update:company create:job update:job'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);

    // Restart any existing sessions the user has.
    this.restartSession();
  }

  handleAuthentication() {
    store.dispatch(authReset);
    store.dispatch(authStart);
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        console.log(authResult);
        this.setSession(authResult);
        store.dispatch(authSuccess());
      } else if (err) {
        store.dispatch(authFailure());
      }
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  getProfile(cb) {
    this.auth0.client.userInfo(this.accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  getExpiryDate() {
    return JSON.stringify(new Date(this.expiresAt));
  }

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');

    // Set the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    console.log('AccessToken', authResult.accessToken);

    // schedule a token renewal
    this.scheduleRenewal();
  }

  renewSession() {
    store.dispatch(authStart());
    this.auth0.checkSession({}, (err, authResult) => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.setSession(authResult);
         store.dispatch(authSuccess());
       } else if (err) {
         this.logout();
         console.log(err);
       }
    });
  }

  /**
   * Uses an existing session when the user first loads the page. Note: this fails silently is a session isn't valid.
   */
  restartSession() {
    store.dispatch(authStart());
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        store.dispatch(authSuccess());
      } else {
        store.dispatch(authFailure());
      }
    });
  }

  scheduleRenewal() {
    let expiresAt = this.expiresAt;
    const timeout = expiresAt - Date.now();
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout);
    }
  }

  login(redirectRoute) {
    if(redirectRoute) {
      this.auth0.authorize({
        redirectUri: `${AUTH_CALLBACK}?${this.REDIRECT_PARAM}=${encodeURIComponent(redirectRoute)}`
      });
      return;
    }
  }

  logout() {
    store.dispatch(authStart());
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');

    // Restore the state of the auth store to default;
    store.dispatch(authReset());

    // Clear token renewal
    clearTimeout(this.tokenRenewalTimeout);

    // Logout against the auth0 API
    this.auth0.logout({returnTo: AUTH_LOGOUT});
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }
}