import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import {Badge} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

import './JobsListing.css';

import TechTags from './../TechTags/TechTags';

class JobsListing extends Component {

  constructor(props) {
    super(props);

    this.onCompanyClick = this.onCompanyClick.bind(this);
    this.onTechnologyClick = this.onTechnologyClick.bind(this);
  }

  onCompanyClick() {
    const {job, onFilterChange} = this.props;
    onFilterChange('company_id', job.company_id);
  }

  onTechnologyClick(technology) {
    const {filters, onFilterChange} = this.props;
    onFilterChange('tech_tags', (filters.tech_tags || []).concat(technology));
  }

  render() {
    const {job} = this.props;
    return (
      <div className="job-listing" id="job_{job.job_id}">
        {!job.placeholder && job.remote === true && (
          <Badge variant="primary" size="md" className="job-remote-badge float-right">Remote</Badge>
        )}
        <h3 className="job-title">
          {job.job_id && job.title && (
            <Link to={`/job/${job.job_id}`}>
              {job.title}
            </Link>
          )}
        </h3>
        <div className="job-details">
          <Link to={`/company/${job.company_id}`}>
            {job.company_name}
          </Link>
          {job.location && (
            <span>
              <span className="job-details-divider">&nbsp;&middot;&nbsp;</span>
              {job.location}
            </span>
          )}
        </div>
        <div className="job-description">
          <Truncate lines={3}>
            <ReactMarkdown className="markdown-content">
              {job.description}
            </ReactMarkdown>
          </Truncate>
        </div>
        <div className="job-technologies">
          <TechTags tags={job.tech_tags} onClickTag={this.onTechnologyClick} />
        </div>
      </div>
    );
  }
}

JobsListing.propTypes = {
  job: PropTypes.shape({
    job_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tech_tags: PropTypes.arrayOf(PropTypes.string),
    company_id: PropTypes.string.isRequired,
    location: PropTypes.string,
    remote: PropTypes.bool,
    type: PropTypes.string,
    salary_range_lower: PropTypes.number,
    salary_range_upper: PropTypes.number,
    salary_period: PropTypes.string
  }).isRequired
};

export default JobsListing;