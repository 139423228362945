import fetch from 'cross-fetch';
import urljoin from 'url-join';

import auth from './auth';
import {API_BASE_PATH} from './config';

/**
 * Append the default headers required to interact with the backend API. Note: This will not overwrite request-specific
 * headers which intersect with the default API headers.
 * @param headers Object The request-specific headers.
 * @return Object A set of headers containing a combination of request-specific headers and the required API headers.
 * @private
 */
export function _appendApiHeaders(headers) {
  const apiHeaders = {
    'Content-Type': 'application/json'
  };

  if(auth.isAuthenticated()) {
    apiHeaders['Authorization'] = `Bearer ${auth.getAccessToken()}`;
  }

  return Object.assign({}, headers, apiHeaders);
}

/**
 * Adds the configured base path to any relative paths.
 * @param path String A URI path.
 * @return String The URI with the configured host and path.
 * @private
 */
export function _appendApiBasePath(path) {
  if(!API_BASE_PATH) {
    return path;
  }

  return urljoin(API_BASE_PATH, path);
}

/**
 * Fetches from the jobslist api, ensuring the appropriate credentials, headers, etc are sent. Do not use this method
 * to access external APIs.
 * @param path String
 * @param options Object
 * @return Promise
 */
export function apiFetch(path, options = {}) {
  // Add the required headers and set an appropriate host (if set) for the API calls
  const apiOptions = Object.assign(options, {headers: _appendApiHeaders(options.headers)});
  const apiPath = _appendApiBasePath(path);

  return fetch(apiPath, apiOptions);
}