import React, { Component } from 'react';
import {Badge} from 'react-bootstrap';

export default class JobStatus extends Component {
  render() {
    const {job} = this.props;
    const isClosed = (job.close_date && new Date(job.close_date) < new Date());

    return (
      <span className="job-status-badges">
        {!isClosed && (
          <Badge variant="success" className="badge-status">Active</Badge>
        )}
        {isClosed && (
          <Badge variant="secondary" className="badge-status">Closed</Badge>
        )}
      </span>
    );
  }
}

