import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

import Skeleton from 'react-loading-skeleton';

import './JobsListCompanyProfile.css';

export default class JobsListCompanyProfile extends Component {
  render() {
    const {company = {}} = this.props;
    return (
      <div className={`company-profile ${this.className || ''}`}>
        <div>
          <h1 className="company-name">{company.name || <Skeleton width={400}/>}</h1>
          <a href={company.website} className="company-website">{company.website || <Skeleton width={100}/>}</a>
          <div className="company-description">
            {!!company.description || <Skeleton count={3} />}
            <ReactMarkdown className="markdown-content">
              {company.description}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    );
  }
}