import {apiFetch} from '../api';
export const COMPANY_LOAD_START = 'COMPANY_LOAD_START';
export const COMPANY_LOAD_SUCCESS = 'COMPANY_LOAD_SUCCESS';
export const COMPANY_LOAD_FAILURE = 'COMPANY_LOAD_FAILURE';
export const COMPANY_SAVE_START = 'COMPANY_SAVE_START';
export const COMPANY_SAVE_SUCCESS = 'COMPANY_SAVE_SUCCESS';
export const COMPANY_SAVE_FAILURE = 'COMPANY_SAVE_FAILURE';
export const COMPANY_SAVE_FAILURE_DISMISS = 'COMPANY_SAVE_FAILURE_DISMISS';

const SAVE_EXCLUDED_KEYS = ['company_id', 'claimable'];

function loadCompanyStart(companyId) {
  return {
    type: COMPANY_LOAD_START,
    companyId
  };
}

function saveCompanyStart(companyId) {
  return {
    type: COMPANY_SAVE_START,
    companyId
  };
}

function loadCompanySuccess(companyId, payload) {
  return {
    type: COMPANY_LOAD_SUCCESS,
    companyId,
    company: payload
  };
}

function saveCompanySuccess(companyId, payload) {
  return {
    type: COMPANY_SAVE_SUCCESS,
    companyId,
    company: payload
  };
}

function loadCompanyFailure(companyId) {
  return {
    type: COMPANY_LOAD_FAILURE,
    companyId
  }
}

function saveCompanyFailure(companyId) {
  return {
    type: COMPANY_SAVE_FAILURE,
    companyId
  };
}

export function saveCompanyFailureDismiss(companyId) {
  return {
    type: COMPANY_SAVE_FAILURE_DISMISS,
    companyId
  };
}

export function loadCompany(companyId) {
  return dispatch => {
    dispatch(loadCompanyStart(companyId));
    return apiFetch(`/companies/${encodeURI(companyId)}`)
      .then(response => {
        if(response.status !== 200) {
          dispatch(loadCompanyFailure(companyId));
          return;
        }

        response.json().then((jsonBody) => dispatch(loadCompanySuccess(companyId, jsonBody)));
      })
      .catch(error => dispatch(loadCompanyFailure(companyId)));
  }
}

export function saveCompany(company) {
  return dispatch => {
    const companyId = company.company_id;
    dispatch(saveCompanyStart(companyId));

    const companyPayload = Object.keys(company).reduce((payload, companyKey) => {
      const companyValue = company[companyKey];
      if(!SAVE_EXCLUDED_KEYS.includes(companyKey)) {
        payload[companyKey] = companyValue;
      }
      return payload;
    }, {});

    return apiFetch(`/companies/${encodeURI(companyId)}`, {
      method: "PATCH",
      body: JSON.stringify(companyPayload)
    })
      .then(response => {
        if (response.status !== 200) {
          dispatch(saveCompanyFailure(companyId));
          return;
        }

        response.json().then((jsonBody) => dispatch(saveCompanySuccess(companyId, jsonBody)));
      })
      .catch(error => dispatch(saveCompanyFailure(companyId)));
  }
}

export function createCompany(company) {
  /*
  return dispatch => {
    dispatch(saveCompanyStart());
    return apiFetch('/user/companies', {
      method: "POST",
      body: JSON.stringify(company)
    })
      .then(response => {
        if (response.status !== 200) {
          dispatch(saveCompanyFailure());
          return;
        }

        response.json().then((jsonBody) => dispatch(saveCompanySuccess(companyId, jsonBody)));
      })
      .catch(error => dispatch(saveCompanyFailure()));
  }
  */
}