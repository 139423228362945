import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import 'react-app-polyfill/ie9';
import 'url-polyfill';

import App from './containers/App/App';
import './bootstrap/bootstrap.css';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './index.css';

import {store} from './state';

render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);