import {
  JOB_CREATE_EMPTY,
  JOB_LOAD_START,
  JOB_SAVE_START,
  JOB_CREATE_START,
  JOB_LOAD_SUCCESS,
  JOB_SAVE_SUCCESS,
  JOB_CREATE_SUCCESS,
  JOB_LOAD_FAILURE,
  JOB_SAVE_FAILURE,
  JOB_CREATE_FAILURE,
  JOB_SAVE_FAILURE_DISMISS,
  JOB_CREATE_COMPLETE,
  JOB_CREATE_ID
} from '../actions/jobs';

const defaultJob = {
  title: "",
  description: "",
  tech_tags: [],
  location: "",
  remote: false,
  type: "f",
  salary_range_lower: "",
  salary_range_upper: "",
  salary_period: "Y",
  application_uri: "",
  contact_email: ""
};

function buildNewState (state, jobId, mutations) {
  const newState = Object.assign({}, state);
  newState[jobId] = Object.assign({}, state[jobId], mutations);

  return newState;
}

export default function jobs(state = {}, action) {
  let newState = state;

  switch(action.type) {
    case JOB_CREATE_EMPTY:
      newState = buildNewState(newState, JOB_CREATE_ID, {
        isLoading: false,
        isError: false,
        isSaveError: false,
        isSaving: false,
        isSaved: false,
        jobId: null,
        job: Object.assign({}, defaultJob, {company_id: action.companyId})
      });
      break;
    case JOB_LOAD_START:
      newState = buildNewState(newState, action.jobId, {
        isLoading: true,
        isError: false
      });
      break;
    case JOB_SAVE_START:
      newState = buildNewState(newState, action.jobId, {
        isSaveError: false,
        isSaving: true
      });
      break;
    case JOB_CREATE_START:
      newState = buildNewState(newState, JOB_CREATE_ID, {
        isLoading: false,
        isSaveError: false,
        isSaving: true
      });
      break;
    case JOB_LOAD_SUCCESS:
      newState = buildNewState(newState, action.jobId, {
        ifError: false,
        isLoading: false,
        job: action.job
      });
      break;
    case JOB_SAVE_SUCCESS:
      newState = buildNewState(newState, action.jobId, {
        isSaving: false,
        job: action.job
      });
      break;
    case JOB_CREATE_SUCCESS:
      newState = buildNewState(newState, JOB_CREATE_ID, {
        isSaving: false,
        jobId: action.jobId
      });

      newState = buildNewState(newState, action.jobId, {
        isLoading: false,
        isError: false,
        isSaveError: false,
        isSaving: false,
        isSaved: true,
        job: Object.assign({}, action.job, {job_id: action.jobId})
      });
      break;
    case JOB_LOAD_FAILURE:
      newState = buildNewState(newState, action.jobId, {
        isLoading: false,
        isError: true
      });
      break;
    case JOB_SAVE_FAILURE:
      newState = buildNewState(newState, action.jobId, {
        isSaving: false,
        isSaveError: true
      });
      break;
    case JOB_CREATE_FAILURE:
      newState = buildNewState(newState, JOB_CREATE_ID, {
        isSaving: false,
        isSaveError: true
      });
      break;
    case JOB_SAVE_FAILURE_DISMISS:
      newState = buildNewState(newState, action.jobId, {
        isSaveError: false
      });
      break;
    case JOB_CREATE_COMPLETE:
      newState = buildNewState(newState, action.jobId, {
        isLoading: false,
        isError: false,
        isSaving: false,
        isSaved: false,
        jobId: null,
        job: null
      });
      break;
    default:
      break;
  }

  return newState;
}