import React, { Component } from 'react';
import {Collapse} from 'react-bootstrap';

import './ExpandableSidebar.css';

export default class ExpandableSidebar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isSidebarOpen: false
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    this.setState(Object.assign({}, this.state, {isSidebarOpen: !this.state.isSidebarOpen}));
  }

  render() {
    const {className, heading, children} = this.props;

    console.log(children)

    return (
      <div>
        <div className={`${className} ex-sidebar`}>
          <div className={`${className}-head ex-sidebar-head`} onClick={this.toggleSidebar}>
            <h2>{heading}</h2>
          </div>
          <Collapse className={`${className}-body ex-sidebar-body`} in={this.state.isSidebarOpen}>
            <div>
              {children}
            </div>
          </Collapse>
        </div>
        <div className="ex-sidebar-space">
        </div>
      </div>

    );
  }
}