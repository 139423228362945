import React, { Component } from 'react';

import './Checkbox.css';

/**
 * Convenience component for pairing checkboxes and labels and getting an easy-to-use onChange event out of checkboxes.
 */
export default class Checkbox extends Component {
  handleInputChange(event) {
    this.props.onChange(event.target.checked);
  }

  render() {
    const {id, name, checked = false, label, style, className} = this.props;

    return (
      <div className={`checkbox ${checked ? 'checkbox-checked' : ''} ${className || ''}`} style={style}>
        <input
          id={id}
          className="checkbox-input"
          name={name}
          type="checkbox"
          checked={checked}
          onChange={this.handleInputChange.bind(this)} />
        <label htmlFor={id} className="checkbox-label">
          {label}
        </label>
      </div>
    );
  }
}
