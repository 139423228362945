import React, { Component } from 'react';

export default class PageContent extends Component {
  render() {
    const {applicationUri, contactEmail, block = false, className} = this.props;

    return (
      <div className={`job-application-buttons ${className}`}>
        {applicationUri && (
          <a href={applicationUri} className={`btn btn-primary ${block && 'btn-block'}`}>Apply online</a>
        )}
        {!applicationUri && contactEmail && (
          <a href={`mailto:${contactEmail}`} className={`btn btn-primary ${block && 'btn-block'}`}>Enquire now</a>
        )}
        {applicationUri && contactEmail && (
          <a href={`mailto:${contactEmail}`} className={`btn btn-outline-secondary ${block && 'btn-block'}`}>Contact this employer</a>
        )}
      </div>
    );
  }
}