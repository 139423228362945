import React, { Component } from 'react';
import {Badge} from 'react-bootstrap';

import './TechTags.css';

export default class PageContent extends Component {
  render() {
    const {tags, onClickTag} = this.props;

    return (
      <div className="badge-set badge-set-technology">
        {tags.map(technology => (
          <Badge key={technology} variant="dark" className="badge-technology" onClick={() => onClickTag(technology)}>{technology}</Badge>
        ))}
      </div>
    );
  }
}

