import React, { Component } from 'react';
import * as moment from 'moment';

import {InputGroup} from 'react-bootstrap';
import TimePicker from 'rc-time-picker';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/pro-regular-svg-icons';

import 'rc-time-picker/assets/index.css';
import './TimePicker.css';

export default class BootstrapTimePicker extends Component {

  state = {};

  constructor(props) {
    super(props);

    this.state = {
      timePickerOpen: false
    };

    this.toggleTimePicker = this.toggleTimePicker.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  toggleTimePicker() {
    const newState = Object.assign({}, this.state);
    newState.timePickerOpen = !newState.timePickerOpen;
    this.setState(newState);
  }

  onFocus() {
    const newState = Object.assign({}, this.state);
    newState.timePickerOpen = true;
    this.setState(newState);
  }

  onClose(event) {
    const newState = Object.assign({}, this.state);
    newState.timePickerOpen = false;
    this.setState(newState);
  }

  render() {
    const {value} = this.props;
    const {timePickerOpen} = this.state;

    console.log('SETTING VALUE', moment.utc(value).local())

    return (
      <InputGroup className="time-input-group">
        <InputGroup.Prepend>
          <InputGroup.Text onClick={this.toggleTimePicker}>
            <FontAwesomeIcon icon={faClock}/>
            <span className="sr-only">Open time picker</span>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <TimePicker
          {...this.props}
          value={(value ? moment.utc(value).local() : null)}
          showSecond={false}
          minuteStep={15}
          format="HH:mm"
          placeholder="HH:MM"
          className="time-input form-control"
          focusOnOpen={true}
          onFocus={this.onFocus}
          onClose={this.onClose}
          open={timePickerOpen}
        />
      </InputGroup>
    )
  }

}