import React, { Component } from 'react';

import './LoadingState.css';

export default class LoadingState extends Component {

  render() {
    return (
      <div className="loading-state">
        <div className="loading-state-box">
          <div className="spinner-grow" />
          <div className="loading-state-text">Loading...</div>
        </div>
      </div>
    );
  }
}
