import React, { Component } from 'react';
import {Container, Button, Card, Modal, Form} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import TextEditor from "./../TextEditor/TextEditor";

import './ManageCompany.css';
import JobsListCompanyProfile from "../JobsListCompanyProfile/JobsListCompanyProfile";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

export default class PageContent extends Component {

  state = {};

  constructor(props) {
    super(props);
    const {isLoading, company} = this.props;

    this.state = {
      isInvalid: false,
      isValidated: false
    };

    // If the company is already loaded lets set the initial state now.
    if (!this.state.company && !isLoading && company) {
      this.state.company = company;
    }

    this.onSave = this.onSave.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onShowPreview = this.onShowPreview.bind(this);
    this.onHidePreview = this.onHidePreview.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {isLoading, company} = this.props;

    // When the company is loaded move it into a local state for this form
    if (!this.state.company && !isLoading && company) {
      this.setState({company: company});
    }

    // When the company changes through a navigation change we need to load the new company in.
    if (this.props.currentCompanyId !== prevProps.currentCompanyId && company) {
      this.setState({company: company});
    }
  }

  onSave(event) {
    event.stopPropagation();
    event.preventDefault();

    const {onSave} = this.props;
    const form = event.currentTarget;
    const formValidity = form.checkValidity();

    this.setState(Object.assign({}, this.state, {isInvalid: !formValidity, isValidated: true}));

    if(onSave && formValidity) {
      onSave(this.state.company);
    }
  }

  onFieldChange(event) {
    const newState = Object.assign({}, this.state);

    newState.company[event.target.name] = event.target.value;
    this.setState(newState);
  }

  onShowPreview() {
    this.setState(Object.assign({}, this.state, {showPreview: true}));
  }

  onHidePreview() {
    this.setState(Object.assign({}, this.state, {showPreview: false}));
  }

  render() {
    const {isLoading, isSaving, isSaveError, onSaveErrorDismiss} = this.props;
    const {isInvalid, isValidated, company} = this.state;

    return (
      <div className="manage-company">
        <h2>Company Profile</h2>
        <p>
          Provide a little information on your company to help potential candidates find out more about your
          organisation. This information will be displayed with jobs you advertise.
        </p>
        <Card>
          <Container>
            {(isSaveError && (
              <ErrorMessage onDismiss={onSaveErrorDismiss}>
                <strong>Changes could not be saved.</strong> Please validate all fields are correct and
                try again. If you continue to experience problems saving please
                 <a href="mailto:info@devnq.org">contact us</a>.
              </ErrorMessage>
            ))}
            {(isInvalid && (
              <ErrorMessage onDismiss={onSaveErrorDismiss}>
                <strong>Some form fields are not valid.</strong> Correct any validation errors below and try
                saving again.
              </ErrorMessage>
            ))}
            <Form
              noValidate
              validated={isValidated}
              onSubmit={this.onSave}
            >
              <Form.Group controlId="company-name-input">
                <Form.Label>
                  Company Name
                </Form.Label>
                {(!company || isLoading) && (
                  <Skeleton height={46} className="form-control"/>
                )}
                {company && !isLoading && (
                  <Form.Control
                    type="text"
                    className="form-control-lg"
                    name="name"
                    value={company.name}
                    required={true}
                    title="Name should the public company name. This field is required."
                    onChange={this.onFieldChange}
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  Name is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company-website-input">
                <Form.Label>
                  Website
                </Form.Label>
                {(!company || isLoading) && (
                  <Skeleton height={36} className="form-control"/>
                )}
                {company && !isLoading && (
                  <Form.Control
                    type="url"
                    value={company.website}
                    name="website"
                    placeholder="http://devnq.org"
                    pattern="^(https?)://[^\s/$.?#].[^\s]*$"
                    onChange={this.onFieldChange}
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  Website should be a http or https website url. eg. https://google.com
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company-description-input">
                <Form.Label>Description</Form.Label>
                {(!company || isLoading) && (
                  <Skeleton height={136} className="form-control"/>
                )}
                {company && !isLoading && (
                  <TextEditor
                    value={company.description}
                    id="company-description-input"
                    name="description"
                    maxLength={1000}
                    onChange={this.onFieldChange}
                  />
                )}
              </Form.Group>
              <div className="form-buttons">
                <Button variant="outline-secondary" disabled={(!company || isLoading)} onClick={this.onShowPreview}>
                  Preview
                </Button>
                <Button type="submit" variant="primary" disabled={(!company || isLoading || isSaving)}>
                  {!isSaving && 'Save'}
                  {isSaving && (
                    <span>
                      <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      Saving...
                    </span>
                  )}
                </Button>
              </div>
            </Form>
          </Container>
        </Card>
        <Modal show={this.state.showPreview} onHide={this.onHidePreview}>
          <Modal.Header closeButton>
            <Modal.Title>Company Profile Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JobsListCompanyProfile company={this.state.company} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}