import React, { Component } from 'react';

import {Container, Card, ButtonToolbar, Button} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-light-svg-icons';

import ManageJobsListing from './../ManageJobsListing/ManageJobsListing';
import ManageJobsListingSkeleton from './../ManageJobsListing/ManageJobsListingSkeleton';
import EmptyState from "../EmptyState/EmptyState";

import './ManageJobsList.css';

export default class ManageJobsList extends Component {
  render() {
    const {jobs, isLoading, currentCompanyId} = this.props;

    return (
      <div className="manage-jobs-list">
        <h2>Job Listings</h2>
        <ButtonToolbar className="manage-jobs-toolbar float-right">
          <LinkContainer to={`/manage/${currentCompanyId}/jobs/create`}>
            <Button><FontAwesomeIcon icon={faPlus} /> Add New Listing</Button>
          </LinkContainer>
        </ButtonToolbar>
        <div className="clearfix"></div>
        <Card>
          <Container className="manage-jobs-list">
            {!isLoading && jobs && jobs.map((job) => {
              return (
                <ManageJobsListing key={job.job_id} job={job} />
              )
            })}
            {!isLoading && jobs.length === 0 && (
                <EmptyState title="You don't have any jobs listed" icon="empty-manage">
                  <p>
                    Get your role in front of qualified candidates in North Queensland.
                  </p>
                  <Button><FontAwesomeIcon icon={faPlus} /> Add New Listing</Button>
                </EmptyState>
            )}
            {isLoading && (
              <div>
                {Array(3).fill({}).map((job, index) => (
                  <ManageJobsListingSkeleton key={index}/>
                ))}
              </div>
            )}
          </Container>
        </Card>
      </div>
    );
  }
}