import React, { Component } from 'react';
import {connect} from 'react-redux';
import queryString from 'querystring';

import JobsList from '../components/JobsList/JobsList';
import {loadJobs, changeFilter, changeFilters, resetFilters} from '../actions/jobsList';
import {loadCompany} from '../actions/companies';

const QUERY_FILTER_KEYS = [
  'location',
  'tech_tags',
  'type',
  'remote'
];

const QUERY_FILTER_BOOLS = [
  'remote'
];

const QUERY_FILTER_ARRAY = [
  'location',
  'tech_tags'
];

class JobsListRoute extends Component {

  componentDidMount() {
    const {dispatch, location} = this.props;

    // Process query params to get filters on initialisation
    const newFilters = this.getFiltersFromURL(location);
    dispatch(changeFilters(Object.assign(newFilters, this.getDefaultFilters())));
  }

  componentDidUpdate() {
    const {dispatch, filters, shouldLoad, history, location, companies} = this.props;
    if (shouldLoad) {
      this.pushFiltersToURL(filters, history, location);
      dispatch(loadJobs(filters));

      if(filters.company_id && !companies[filters.company_id]) {
        dispatch(loadCompany(filters.company_id));
      }
    }
  }

  /**
   * Default filters are filters that are ALWAYS applied in this particular view. They set the minimum set of filters
   * to be applied for views like company-specific jobs listing pages and will not be removed during a filter reset.
   * @return {*}
   */
  getDefaultFilters() {
    const defaultFilters = {};
    const companyId = this.props.match.params.company_id;

    if (companyId) {
      defaultFilters['company_id'] = companyId;
    }

    return defaultFilters;
  }

  getFiltersFromURL(location) {
    const cleanSearch = (location.search || "").replace(/^\?/, '');
    const query = queryString.parse(cleanSearch);
    return QUERY_FILTER_KEYS.reduce((newFilters, filterKey) => {
      if(query[filterKey]) {
        if(QUERY_FILTER_BOOLS.includes(filterKey)) {
          newFilters[filterKey] = (query[filterKey] === 'true') ? true : false;
        } else if(QUERY_FILTER_ARRAY.includes(filterKey) && !Array.isArray(query[filterKey])){
          newFilters[filterKey] = [query[filterKey]];
        } else {
          newFilters[filterKey] = query[filterKey];
        }
      }

      return newFilters;
    }, {});
  }

  pushFiltersToURL(filters, history, location) {
    // Set the query params for this page to the new filter state
    const relevantFilters = Object.keys(filters)
      .filter((filterKey) => {
        const currentFilter = filters[filterKey];
        return !!currentFilter && QUERY_FILTER_KEYS.includes(filterKey);
      })
      .reduce((relevantFilters, relevantKey) => {
        relevantFilters[relevantKey] = filters[relevantKey];
        return relevantFilters
      }, {});
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(relevantFilters),
      hash: location.hash
    });
  }

  onFilterChange(filterKey, filterValue) {
    const {dispatch} = this.props;
    dispatch(changeFilter(filterKey, filterValue));
  }

  onFiltersReset() {
    const {dispatch} = this.props;
    dispatch(resetFilters(this.getDefaultFilters()));
  }

  render() {
    const {jobs, companies, filters, shouldLoad, isLoading, isError} = this.props;
    const companyState = companies[filters.company_id] || null;

    return (
      <JobsList
        jobs={jobs}
        companyState={companyState}
        filters={filters}
        shouldLoad={shouldLoad}
        isLoading={isLoading}
        isError={isError}
        onFilterChange={this.onFilterChange.bind(this)}
        onFiltersReset={this.onFiltersReset.bind(this)}
      />
    )
  }
}

function mapStateToProps(state) {
  const {companies, jobsList} = state;
  const {shouldLoad, isLoading, isError, filters, items} = jobsList;

  return {
    shouldLoad,
    isLoading,
    isError,
    jobs: items,
    filters,
    companies
  };
}

export default connect(mapStateToProps)(JobsListRoute)