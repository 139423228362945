import React, { Component } from 'react';

const SALARY_PERIOD_HOURLY = 'H';
const SALARY_PERIOD_MONTHLY = 'M';
const SALARY_PERIOD_YEARLY = 'Y';
//const SALARY_PERIOD_TOTAL = 'T';

const SALARY_PERIOD_LABEL_ENUM = {};
SALARY_PERIOD_LABEL_ENUM[SALARY_PERIOD_HOURLY] = 'hour';
SALARY_PERIOD_LABEL_ENUM[SALARY_PERIOD_MONTHLY] = 'month';
SALARY_PERIOD_LABEL_ENUM[SALARY_PERIOD_YEARLY] = 'annum';

export default class JobSalaryRange extends Component {
  render() {
    const {rangeLower, rangeUpper, rangePeriod, className} = this.props;
    let periodLabel = "";

    if(Object.keys(SALARY_PERIOD_LABEL_ENUM).includes(rangePeriod)) {
      periodLabel = `per ${SALARY_PERIOD_LABEL_ENUM[rangePeriod]}`;
    }

    return (
      <div className={`job-salary-range ${className}`}>
        {(rangeLower && !rangeUpper) && (
          <span className="range-from">
            From ${rangeLower} {periodLabel}
          </span>
        )}
        {(rangeUpper && !rangeLower) && (
          <span className="range-to">
            Up to ${rangeUpper} {periodLabel}
          </span>
        )}
        {(rangeUpper && rangeLower) && (
          <span className="range-full">
            ${rangeLower}-${rangeUpper} {periodLabel}
          </span>
        )}
        {(!rangeUpper && !rangeLower) && (
          <span className="range-none">
            On Enquiry
          </span>
        )}
      </div>
    );
  }
}