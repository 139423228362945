import {
  JOBS_CHANGE_FILTER,
  JOBS_CHANGE_ALL_FILTERS,
  JOBS_RESET_ALL_FILTERS,
  JOBS_LOAD_START,
  JOBS_LOAD_SUCCESS,
  JOBS_LOAD_FAILURE,
} from '../actions/jobsList';


export const JOBS_DEFAULT_STATE =  {
  shouldLoad: true,
  isLoading: false,
  isError: false,
  filters: {},
  items: []
};

function _hasFilterStateChanged(prevState, newState) {
  return (JSON.stringify(prevState) !== JSON.stringify(newState));
}

export default function jobs(state = JOBS_DEFAULT_STATE, action) {
  switch(action.type) {
    case JOBS_CHANGE_FILTER:
      const newFilters = {};
      newFilters[action.filterKey] = action.filterValue;

      if(_hasFilterStateChanged(state.filters, newFilters)) {
        return Object.assign({}, state, {
          filters: Object.assign({}, state.filters, newFilters),
          shouldLoad: true,
          items: []
        });
      }

      return state;
    case JOBS_CHANGE_ALL_FILTERS:
      if(_hasFilterStateChanged(state.filters, action.filters)) {
        return Object.assign({}, state, {
          filters: Object.assign({}, action.filters),
          shouldLoad: true,
          items: []
        });
      }

      return state;
    case JOBS_RESET_ALL_FILTERS:
      const newResetFilters = Object.assign({}, JOBS_DEFAULT_STATE.filters, action.defaultFilters || {})

      if(_hasFilterStateChanged(state.filters, newResetFilters)) {
        return Object.assign({}, state, {
          filters: newResetFilters,
          shouldLoad: true,
          items: []
        });
      }

      return state;
    case JOBS_LOAD_START:
      return Object.assign({}, state, {
        shouldLoad: false,
        ifError: false,
        isLoading: true
      });
    case JOBS_LOAD_SUCCESS:
      return Object.assign({}, state, {
        ifError: false,
        isLoading: false,
        items: action.items
      });
    case JOBS_LOAD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        items: []
      });
    default:
      return state;
  }
}