import React, { Component } from 'react';
import {Row} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import './ManageJobsListings.css';

export default class ManageJobsListing extends Component {
  render() {
    return (
      <Row className="manage-job-listing">
        <div class="job-details-block">
          <div className="job-title"><Skeleton width={Math.floor((Math.random() * 200) + 100)} /></div>
          <div className="job-details">
            <Skeleton width={Math.floor((Math.random() * 300) + 100)} />
          </div>
        </div>
        <div className="job-controls-block">
          <Skeleton height={36} width={240}/>
        </div>
      </Row>
    );
  }
}