import React, { Component } from 'react';
import { withRouter } from "react-router";
import {Dropdown, ButtonGroup} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import pathToRegexp from 'path-to-regexp';

import './CompanyChanger.css';

class CompanyChanger extends Component {
  render() {
    const {userCompanies, currentCompanyId} = this.props;
    const currentCompany = userCompanies.find((userCompany) => userCompany.company_id === currentCompanyId) || {};

    const currentPath = this.props.match.path;
    const currentPathParams = this.props.match.params;

    return (
      <div>
        <h3 className="company-changer-title">{currentCompany.name || 'Unknown'}</h3>
        {userCompanies.length > 1 && (
          <Dropdown as={ButtonGroup} size="sm" className="company-changer-dropdown">
            <Dropdown.Toggle split variant="link"  className="company-changer-button">
              Change Company &nbsp;
            </Dropdown.Toggle>
            <Dropdown.Menu className="company-changer-menu">
              {userCompanies.map((company) => {
                const companyPath = pathToRegexp.compile(currentPath)(
                  Object.assign({}, currentPathParams, {'company_id': company.company_id}));
                return (
                  <LinkContainer key={company.company_id} to={companyPath}>
                    <Dropdown.Item>
                      {company.name}
                    </Dropdown.Item>
                  </LinkContainer>
                )
              })}
              {/*
              <Dropdown.Divider />
              <Dropdown.Item onClick="">
                <FontAwesomeIcon icon={faPlus} /> Add Company
              </Dropdown.Item>
              */}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    );
  }
}

export default withRouter(CompanyChanger);