import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import {Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBriefcase, faBuilding, faTachometer} from '@fortawesome/pro-regular-svg-icons';

import CompanyChanger from './../CompanyChanger/CompanyChanger';
import ExpandableSidebar from './../ExpandableSidebar/ExpandableSidebar';

import './ManageNav.css';

export default class ManageNav extends Component {
  render() {
    const {currentRoute, userCompaniesState, companiesState, currentPath, currentCompanyId} = this.props;
    let userCompanies;

    if(userCompaniesState.hasLoaded && companiesState) {
      userCompanies = userCompaniesState.companyIds.map((userCompanyId) => {
        return companiesState[userCompanyId].company;
      });
    }

    return (
      <ExpandableSidebar className="manage-nav" heading="Employer Tools">
        <div>
          {userCompanies && (
            <CompanyChanger userCompanies={userCompanies} currentPath={currentPath} currentCompanyId={currentCompanyId} />
          )}
          {!userCompanies && (
            <div>
              <Skeleton height={29} width={120}/><br />
              <Skeleton height={21} width={100}/>
            </div>
          )}
          <hr />
          <Nav defaultActiveKey={currentRoute} className="flex-column manage-nav-links">
            <LinkContainer to={`/manage/${currentCompanyId}`}>
              <Nav.Link eventKey={`/manage/${currentCompanyId}`}><FontAwesomeIcon icon={faTachometer} className="mr-2"/> Dashboard</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/manage/${currentCompanyId}/jobs`}>
              <Nav.Link eventKey={`/manage/${currentCompanyId}/jobs`}><FontAwesomeIcon icon={faBriefcase} className="mr-2"/> Job Listings</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/manage/${currentCompanyId}/company`}>
              <Nav.Link eventKey={`/manage/${currentCompanyId}/company`}><FontAwesomeIcon icon={faBuilding} className="mr-2"/> Company Profile</Nav.Link>
            </LinkContainer>
            <hr />
            <small>
              <Nav.Link href="mailto:info@devnq.org">Support</ Nav.Link>
              <Nav.Link eventKey="logout">Logout</Nav.Link>
            </small>
          </Nav>
        </div>
      </ExpandableSidebar>
    );
  }
}