import {apiFetch} from '../api';
import queryString from 'querystring';

export const JOBS_CHANGE_FILTER = 'JOBS_CHANGE_FILTER';
export const JOBS_CHANGE_ALL_FILTERS = 'JOBS_CHANGE_ALL_FILTERS';
export const JOBS_RESET_ALL_FILTERS = 'JOBS_RESET_ALL_FILTERS';
export const JOBS_LOAD_START = 'JOBS_LOAD_START';
export const JOBS_LOAD_SUCCESS = 'JOBS_LOAD_SUCCESS';
export const JOBS_LOAD_FAILURE = 'JOBS_LOAD_FAILURE';

export function changeFilter(filterKey, filterValue) {
  return {
    type: JOBS_CHANGE_FILTER,
    filterKey,
    filterValue
  };
}

export function changeFilters(filters) {
  return {
    type: JOBS_CHANGE_ALL_FILTERS,
    filters
  }
}

export function resetFilters(defaultFilters) {
  return {
    type: JOBS_RESET_ALL_FILTERS,
    defaultFilters
  }
}

function loadJobsStart() {
  return {
    type: JOBS_LOAD_START
  };
}

function loadJobsSuccess(payload) {
  return {
    type: JOBS_LOAD_SUCCESS,
    items: payload.results
  };
}

function loadJobsFailure() {
  return {
    type: JOBS_LOAD_FAILURE,
  }
}

export function loadJobs(filters) {
  return dispatch => {
    dispatch(loadJobsStart());
    return apiFetch('/jobs?'+queryString.stringify(filters))
      .then(response => {
        if(response.status !== 200) {
          dispatch(loadJobsFailure());
          return;
        }

        response.json().then((jsonBody) => dispatch(loadJobsSuccess(jsonBody)));
      })
      .catch(error => dispatch(loadJobsFailure(error)));
  }
}

