import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Fade} from 'react-bootstrap';
import {withRouter} from "react-router-dom";

import ErrorState from './../ErrorState/ErrorState';
import LoadingState from "../LoadingState/LoadingState";

import auth from './../../auth';

class AuthenticatedContent extends Component {

  constructor(props) {
    super(props);
    const {onAuthenticate} = props;
    const isAuthenticated = auth.isAuthenticated();

    // The default state is assumed to be unauthenticated. If the state is already authenticated we trigger
    // onAuthenticate to ensure all users are aware we're authenticated.
    if(isAuthenticated) {
      onAuthenticate();
    }

    this.state = {lastAuthenticateState: isAuthenticated};
  }

  componentDidUpdate() {
    const isAuthenticated = auth.isAuthenticated();
    if(this.state.lastAuthenticateState !== isAuthenticated) {
      this.triggerEvents();
      this.setState({lastAuthenticateState: isAuthenticated});
    }
  }

  triggerEvents() {
    const {onAuthenticate, onDeauthenticate} = this.props;
    const isAuthenticated = auth.isAuthenticated();

    switch(isAuthenticated) {
      case true:
        if(onAuthenticate) {
          onAuthenticate();
        }
        break;
      case false:
        if(onDeauthenticate) {
          onDeauthenticate();
        }
        break;
      default:
        break;
    }
  }

  render() {
    const {isLoading, isError, hasAttempted, location} = this.props;
    const isAuthenticated = auth.isAuthenticated();

    if(hasAttempted && !isLoading && !isAuthenticated) {
      auth.login(`${location.pathname}${location.search}`);
    }

    return (
      <div>
        {isAuthenticated && (
          <Fade in={isAuthenticated}>
            <div className="authenticated-content">
              {this.props.children}
            </div>
          </Fade>
        )}
        {(!hasAttempted || isLoading) && (
          <LoadingState />
        )}
        {(isError || (hasAttempted && !isLoading && !isAuthenticated)) && (
          <ErrorState />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {auth} = state;
  const {isError, isLoading, hasAttempted} = auth;

  return {
    hasAttempted,
    isLoading,
    isError
  };
}

export default connect(mapStateToProps)(withRouter(AuthenticatedContent));