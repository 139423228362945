import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {Navbar, Nav, Button, Collapse} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faTwitter, faYoutube} from '@fortawesome/fontawesome-free-brands';

import './Header.css';

export default class header extends Component {

  state;

  render() {
    const {menuOpen = false} = (this.state || {});

    return (
      <div id="header" className="container">
        <Navbar variant="dark" expand="md">
          <Navbar.Brand href="http://devnq.org">
            <img src="http://devnq.org/assets/images/devnq.svg" className="navbar-brand-image" alt="DevNQ" />
          </Navbar.Brand>
          <Button className="navbar-toggler" type="button" onClick={() => this.setState({ menuOpen: !menuOpen })} aria-controls="mainNavbar" aria-expanded={menuOpen} aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </Button>
          <Collapse className="navbar-collapse" id="mainNavbar" in={menuOpen}>
            <div>
            <Nav className="navbar-nav ml-auto">
              <Nav.Item className="mx-md-3">
                <Link className="nav-link" to="/">Jobs</Link>
              </Nav.Item>
              <Nav.Item className="mx-md-3">
                <a className="nav-link" href="https://devnq.slack.com">Slack</a>
              </Nav.Item>
              <Nav.Item className="mx-md-3">
                <a className="nav-link" href="https://www.meetup.com/dev_nq/">Meetups</a>
              </Nav.Item>
              <Nav.Item className="ml-md-3">
                <a className="nav-link" href="https://twitter.com/dev_nq"><FontAwesomeIcon icon={faTwitter} className="d-md-inline d-none"/><span className="d-inline d-md-none">Twitter</span></a>
              </Nav.Item>
              <Nav.Item className="ml-md-3">
                <a className="nav-link" href="https://www.facebook.com/devnorthqueensland/"><FontAwesomeIcon icon={faFacebook} className="d-md-inline d-none"/><span className="d-inline d-md-none">Facebook</span></a>
              </Nav.Item>
              <Nav.Item className="ml-md-3">
                <a className="nav-link" href="https://www.youtube.com/channel/UCMKRM7oJFptsQYRhpS4KPaA"><FontAwesomeIcon icon={faYoutube} className="d-md-inline d-none"/><span className="d-inline d-md-none">Youtube</span></a>
              </Nav.Item>
            </Nav>
            </div>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}