import React, {Component} from 'react';

import './TextEditor.css';

export default class TextEditor extends Component {
  state;

   constructor(props) {
     super(props);

     this.state = {length: props.value.length};
     this.onChange = this.onChange.bind(this);
   }

  onChange(event){
    this.setState({length: event.target.value.length});

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  render () {
    const {value, id, name, maxLength, placeholder} = this.props;
    const {length} = this.state;

    return (
      <div className="text-editor form-group">
        <div className="text-editor-box">
          <textarea
            id={id}
            name={name}
            maxLength={maxLength}
            onChange={this.onChange}
            value={value}
            placeholder={placeholder}
          />
          <div className="text-editor-footer">
            {maxLength && (
              <span className="float-right">
                {length}/{maxLength}
              </span>
            )}
            Markdown is supported for this field.
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  }
}