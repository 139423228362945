import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Row, Button, ButtonGroup, Dropdown} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

import JobTypeLabel from './../JobTypeLabel/JobTypeLabel';
import JobStatus from './../JobStatus/JobStatus';

import './ManageJobsListings.css';

export default class ManageJobsListing extends Component {
  render() {
    const {job} = this.props;

    return (
      <Row className="manage-job-listing d-flex">
        <div class="job-details-block">
          <div className="job-title"><Link to={`/manage/${job.company_id}/jobs/${job.job_id}`}>{job.title}</Link></div>
          <div className="job-details">
            <JobStatus job={job} />
            <span className="job-location">{job.location || (<em>No Location</em>)}</span>
            <span className="job-details-divider">&nbsp;&middot;&nbsp;</span>
            <JobTypeLabel jobType={job.type} />
          </div>
        </div>
        <div className="job-controls-block">
          <Dropdown as={ButtonGroup} size="sm" className="manage-job-dropdown d-flex">
            <LinkContainer to={`/manage/${job.company_id}/jobs/${job.job_id}`}>
              <Button size="sm" variant="secondary" className="manage-job-btn-edit">Edit Listing</Button>
            </LinkContainer>
            <Dropdown.Toggle split variant="outline-secondary" className="manage-job-btn-carot" />
            <Dropdown.Menu alignRight={true} className="manage-job-menu">
              <LinkContainer to={`/job/${job.job_id}`}>
                <Dropdown.Item>
                  View Public Listing
                </Dropdown.Item>
              </LinkContainer>
              <Dropdown.Item>
                Close Listing
              </Dropdown.Item>
              <Dropdown.Item>
                Delete Listing
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Row>
    );
  }
}