import React, { Component } from 'react';

import './EmptyState.css';

export default class EmptyState extends Component {

  render() {
    const {title, icon, children} = this.props;

    return (
      <div className="empty-state">
        <div className="empty-state-box">
          {icon && (
            <img className="empty-state-icon" src={`/images/graphics/${icon}.svg`} alt={title || 'No Results Found'} />
          )}
          <h2>{title || 'No Results Found'}</h2>
          <div class="empty-state-content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}
