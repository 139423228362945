import React, { Component } from 'react';
import {connect} from 'react-redux';

import {loadJob} from './../actions/jobs';
import {loadCompany} from './../actions/companies';
import JobFull from './../components/JobFull/JobFull';

class JobRoute extends Component {

  componentDidMount() {
    const {dispatch, jobs, companies} = this.props;
    const jobId = this.props.match.params.job_id;

    if(!jobs[jobId]) {
      dispatch(loadJob(jobId));
    } else if(jobs[jobId] && jobs[jobId].job && !companies[jobs[jobId].job.company_id]) {
      this.loadCompanyInfo(jobs[jobId].company_id);
    }
  }

  componentDidUpdate() {
    const {jobs, companies} = this.props;
    const jobId = this.props.match.params.job_id;

    if(jobs[jobId] && jobs[jobId].job && !companies[jobs[jobId].job.company_id]) {
      this.loadCompanyInfo(jobs[jobId].job.company_id);
    }
  }

  loadCompanyInfo(companyId) {
    const {dispatch} = this.props;
    dispatch(loadCompany(companyId));
  }

  render() {
    const jobId = this.props.match.params.job_id;
    const {jobs = {}, companies = {}} = this.props;
    const jobState = jobs[jobId] || {};
    const companyId = jobState.job && jobState.job.company_id;
    const companyState = (companyId && companies[companyId]) || {};

    return (
      <JobFull
        jobState={jobState}
        companyState={companyState}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    jobs: state.jobs,
    companies: state.companies
  };
}

export default connect(mapStateToProps)(JobRoute)