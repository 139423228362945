import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Redirect } from "react-router-dom";

import AuthenticatedContent from './../components/AuthenticatedContent/AuthenticatedContent';
import {loadUserCompanies} from '../actions/userCompanies';
import LoadingState from './../components/LoadingState/LoadingState';

class ManageRedirectRoute extends Component {

  constructor(props) {
    super(props);

    this.onAuthenticate = this.onAuthenticate.bind(this);
  }

  onAuthenticate() {
    const {dispatch, userCompaniesState} = this.props;

    if(!userCompaniesState.hasLoaded) {
      dispatch(loadUserCompanies());
    }
  }

  render() {
    const {userCompaniesState} = this.props;

    return (
      <AuthenticatedContent onAuthenticate={this.onAuthenticate}>
        {(!userCompaniesState.companyIds || userCompaniesState.isLoading) && (
          <LoadingState />
        )}
        {userCompaniesState.companyIds && (
          <Redirect to={`/manage/${userCompaniesState.companyIds[0]}`} />
        )}
      </AuthenticatedContent>
    )
  }
}

function mapStateToProps(state) {
  const {userCompanies} = state;

  return {
    userCompaniesState: userCompanies
  };
}

export default connect(mapStateToProps)(ManageRedirectRoute)