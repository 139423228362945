import React, { Component } from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';

import JobsListing from './../JobsListing/JobsListing';
import JobsListingSkeleton from './../JobsListing/JobsListingSkeleton';
import JobsFilters from './../JobsFilters/JobsFilters';
import ErrorState from './../ErrorState/ErrorState';
import EmptyState from './../EmptyState/EmptyState';
import JobsListCompanyProfile from './../JobsListCompanyProfile/JobsListCompanyProfile';

export default class JobsList extends Component {
  render() {
    const {jobs = [], companyState, filters, shouldLoad, isLoading, isError, onFilterChange, onFiltersReset} = this.props;

    return (
      <Container className="jobs_list">
        {companyState && !companyState.isError && (
        <Row>
          <Col xs={12}>
            <JobsListCompanyProfile company={companyState.company} />
          </Col>
        </Row>
        )}
        <Row>
          <Col md={4} lg={3}>
            <JobsFilters filters={filters} onFilterChange={onFilterChange} onFiltersReset={onFiltersReset} />
          </Col>
          <Col md={8} lg={9}>
            <Card>
              {!isLoading && isError && (
                <ErrorState />
              )}
              {!isLoading && !isError && jobs.length === 0 && (
                <EmptyState title="No Jobs Posts Found" icon="empty-jobs">
                  <p>Try widening your search or removing some filters.</p>
                </EmptyState>
              )}
              {jobs.length > 0 && (
                <div>
                  {jobs.map(job => (
                    <JobsListing key={job.job_id} job={job} filters={filters} onFilterChange={onFilterChange} />
                  ))}
                </div>
              )}
              {(shouldLoad || isLoading) && (
                <div>
                  {Array(3).fill({}).map((job, index) => (
                    <JobsListingSkeleton key={index}/>
                  ))}
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}