import React, { Component } from 'react';
import {Alert} from 'react-bootstrap';

export default class ErrorMessage extends Component {

  render() {
    const {children, onDismiss} = this.props;

    return (
      <div className="error-message">
        <Alert variant="danger" dismissible onClose={onDismiss}>
          {children}
        </Alert>
      </div>
    );
  }
}
