import {
  COMPANY_LOAD_START,
  COMPANY_LOAD_SUCCESS,
  COMPANY_LOAD_FAILURE,
  COMPANY_SAVE_START,
  COMPANY_SAVE_SUCCESS,
  COMPANY_SAVE_FAILURE,
  COMPANY_SAVE_FAILURE_DISMISS
} from '../actions/companies';

import {
  USER_COMPANIES_LOAD_SUCCESS
} from '../actions/userCompanies';

function buildNewState (state, companyId, mutations) {
  const newState = Object.assign({}, state);
  newState[companyId] = Object.assign({}, state[companyId], mutations)

  return newState;
}

export default function companies(state = {}, action) {
  let newState = Object.assign({}, state);

  switch(action.type) {
    case COMPANY_LOAD_START:
      newState = buildNewState(newState, action.companyId, {
        isLoading: true,
        isLoadError: false,
        isSaveError: true, //TODO: Remove
      });

      break;
    case COMPANY_SAVE_START:
      newState = buildNewState(newState, action.companyId, {
        isSaveError: false,
        isSaving: true
      });
      break;
    case USER_COMPANIES_LOAD_SUCCESS:
      // User Companies loads a set of companies in bulk, we cross-load them to the companies store
      action.companies.forEach((company) => {
        newState = buildNewState(newState, company.company_id, {
          isLoadError: false,
          isLoading: false,
          company: company
        });
      })
      break;
    case COMPANY_LOAD_SUCCESS:
      newState = buildNewState(newState, action.companyId, {
        isLoadError: false,
        isLoading: false,
        company: action.company
      });
      break;
    case COMPANY_SAVE_SUCCESS:
      newState = buildNewState(newState, action.companyId, {
        isSaving: false,
        company: action.company
      });
      break;
    case COMPANY_LOAD_FAILURE:
      newState = buildNewState(newState, action.companyId, {
        isLoading: false,
        isLoadError: true
      });
      break;
    case COMPANY_SAVE_FAILURE:
      newState = buildNewState(newState, action.companyId, {
        isSaving: false,
        isSaveError: true
      });
      break;
    case COMPANY_SAVE_FAILURE_DISMISS:
      newState = buildNewState(newState, action.companyId, {
        isSaveError: false
      });
      break;
    default:
      break;
  }

  return newState;
}