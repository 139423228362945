import React, { Component } from 'react';

import Checkbox from './../Checkbox/Checkbox'

/**
 * Convenience component for pairing checkboxes and labels and getting an easy-to-use onChange event out of checkboxes.
 */
export default class CheckboxSet extends Component {

  state;

  constructor(props) {
    super(props);
    this.state = {
      checkedValues: props.checkedValues || []
    };
  }

  componentDidUpdate(prevProps) {
    const {checkedValues} = this.props;
    if (JSON.stringify(checkedValues) !== JSON.stringify(prevProps.checkedValues)) {
      this.setState(Object.assign(this.state, {checkedValues}));
    }
  }

  handleInputChangeFactory(key) {
    return (isCheckboxChecked) => {
      let newCheckedValues = Array.from(this.state.checkedValues);
      const keyIndex = newCheckedValues.indexOf(key);

      if(isCheckboxChecked && keyIndex === -1) {
        newCheckedValues.push(key);
      } else if (!isCheckboxChecked && keyIndex >= 0) {
        newCheckedValues.splice(keyIndex, 1);
      }

      this.setState(Object.assign(this.state, {checkedValues: newCheckedValues}));
      this.props.onChange(newCheckedValues);
    }
  }

  render() {
    const {id, checkboxes, checkedValues = [], style, className} = this.props;

    return (
      <fieldset id={id} className={`checkbox-set ${className || ''}`} style={style}>
        {checkboxes.map((checkboxOptions) => {
          return (
            <Checkbox
              key={checkboxOptions.key}
              id={`${id}_${checkboxOptions.key}`}
              name={checkboxOptions.key}
              label={checkboxOptions.label}
              checked={checkedValues.includes(checkboxOptions.key)}
              onChange={this.handleInputChangeFactory(checkboxOptions.key).bind(this)}
            />
          )
        })}
      </fieldset>
    );
  }
}