import {apiFetch} from '../api';
export const USER_COMPANIES_LOAD_START = 'USER_COMPANIES_LOAD_START';
export const USER_COMPANIES_LOAD_SUCCESS = 'USER_COMPANIES_LOAD_SUCCESS';
export const USER_COMPANIES_LOAD_FAILURE = 'USER_COMPANIES_LOAD_FAILURE';


function loadUserCompaniesStart() {
  return {
    type: USER_COMPANIES_LOAD_START,
  };
}

function loadUserCompaniesSuccess(payload) {
  return {
    type: USER_COMPANIES_LOAD_SUCCESS,
    companies: payload
  };
}

function loadUserCompaniesFailure() {
  return {
    type: USER_COMPANIES_LOAD_FAILURE
  }
}

export function loadUserCompanies() {
  return dispatch => {
    dispatch(loadUserCompaniesStart());
    return apiFetch('/user/companies')
      .then(response => {
        if(response.status !== 200) {
          dispatch(loadUserCompaniesFailure());
          return;
        }

        response.json().then((jsonBody) => dispatch(loadUserCompaniesSuccess(jsonBody)));
      })
      .catch(error => dispatch(loadUserCompaniesFailure()));
  }
}
