import React, { Component } from 'react';
import {Container, Row, Col, Nav} from 'react-bootstrap';
import { Link } from "react-router-dom";

import './Footer.css';

export default class JobsList extends Component {
  render() {
    return (
      <div className="footer">
        <Container className="footer-container">
          <Row>
            <Col md={3} sm={6}>
              <h2>Jobs</h2>
              <Nav className="footer-nav flex-column">
                <Nav.Item>
                  <Link className="nav-link" to="/">Find Jobs</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link className="nav-link" to="/manage">Add/Manage Job Listings</Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={3}  sm={6}>
              <h2>Tools</h2>
              <Nav className="footer-nav flex-column">
                <Nav.Item>
                  <a className="nav-link" href="https://stackoverflow.com/jobs/salary">Salary Calculator</a>
                </Nav.Item>
                <Nav.Item>
                  <a className="nav-link" href="https://www.joelonsoftware.com/2000/08/09/the-joel-test-12-steps-to-better-code/">The Joel Test</a>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={3} sm={6}>
              <h2>DevNQ</h2>
              <Nav className="footer-nav flex-column">
                <Nav.Item>
                  <a className="nav-link" href="https://devnq.org">About</a>
                </Nav.Item>
                <Nav.Item>
                  <a className="nav-link" href="https://devnq.org">Contact</a>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={3} sm={6}>
              <h2>Social</h2>
              <Nav className="footer-nav flex-column">
                <Nav.Item>
                  <a className="nav-link" href="https://www.meetup.com/dev_nq/">Meetup</a>
                </Nav.Item>
                <Nav.Item>
                  <a className="nav-link" href="https://facebook.com/devnorthqueensland/">Facebook</a>
                </Nav.Item>
                <Nav.Item>
                  <a className="nav-link" href="https://twitter.com/dev_nq">Twitter</a>
                </Nav.Item>
                <Nav.Item>
                  <a className="nav-link" href="https://www.youtube.com/channel/UCMKRM7oJFptsQYRhpS4KPaA">Youtube</a>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <div className="">
            Privacy Policy &middot; Terms and Conditions &middot; &copy; DevNQ 2017 - {new Date().getFullYear()}
          </div>
        </Container>
      </div>
    );
  }
}