import React, { Component } from 'react';

import {Card, Button} from 'react-bootstrap';

import Checkbox from './../Checkbox/Checkbox';
import CheckboxSet from './../CheckboxSet/CheckboxSet';
import JobsFilterTechnology from './../JobsFilterTechnology/JobsFilterTechnology';
import JobsFilterLocation from './../JobsFilterLocation/JobsFilterLocation';

import {JOB_TYPE_FULL_TIME, JOB_TYPE_PART_TIME, JOB_TYPE_CASUAL,
  JOB_TYPE_CONTRACT, JOB_TYPE_OTHER} from './../../actions/jobs';

import './JobsFilters.css';

const TYPE_CHECKBOXES =  [
  {key: JOB_TYPE_FULL_TIME, label: 'Full Time'},
  {key: JOB_TYPE_PART_TIME, label: 'Part Time'},
  {key: JOB_TYPE_CASUAL, label: 'Casual'},
  {key: JOB_TYPE_CONTRACT, label: 'Contract'},
  {key: JOB_TYPE_OTHER, label: 'Other'}
];

export default class JobsFilters extends Component {

  state;

  constructor(props) {
    super(props);
    this.state = {};

    this.onFilterComponentChangeFactory = this.onFilterComponentChangeFactory.bind(this);
  }

  onFilterComponentChangeFactory(filterKey) {
    const {onFilterChange} = this.props;
    return (filterValue) => {
      onFilterChange(filterKey, filterValue);
    }
  }

  render() {
    const {filters, onFiltersReset} = this.props;
    return (
      <form className="jobs-filters">
        <div className="jobs-filters-header">
          <Button variant="link" size="sm" className="float-right" onClick={onFiltersReset}>Reset</Button>
          <h2>Filters</h2>
          <div className="clearfix"></div>
        </div>
        <Card>
          <Card.Body>
            <Card.Title>Location</Card.Title>
            <JobsFilterLocation
              id="filter_location"
              onChange={this.onFilterComponentChangeFactory('location')}
              value={filters.location}
            />
            <Checkbox
              id="filter_remote"
              label="Remote roles only"
              onChange={this.onFilterComponentChangeFactory('remote')}
              checked={filters.remote}
              style={{MarginTop: '20px'}}
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Technologies</Card.Title>
            <JobsFilterTechnology
              id="filter_technology"
              onChange={this.onFilterComponentChangeFactory('tech_tags')}
              value={filters.tech_tags}
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Job Type</Card.Title>
            <CheckboxSet
              id="filter_type"
              checkboxes={TYPE_CHECKBOXES}
              checkedValues={filters.type}
              onChange={this.onFilterComponentChangeFactory('type')}
            />
          </Card.Body>
        </Card>
      </form>
    );
  }
}